import {
  memo,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useTheme } from "../../hooks/useTheme";
import { InitialsPic } from "../InitialsPic";
import { Icon } from "@iconify/react";
import {
  AccountData,
  Event,
  AudienceList,
  AudienceListVisibility,
  FollowType,
  Follower,
  SavedFormResponse,
  TicketV2,
  SavedFormQuestion,
  FollowerStatus,
} from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import {
  fetchMostRecentUserAttendedEvents,
  fetchMultipleUserFormResponses,
  fetchUserFollowerData,
  fetchUserEssentialFormResponse,
  fetchUserJoinedAudienceLists,
  fetchUserEssentialQuestion,
  checkAudienceListMembership,
} from "../../utils/userUtils";
import {
  FAVORITES_LIST_NAME,
  GetDateWithTime,
  abbreviateNumber,
  friendlyRoleName,
  sortSavedQuestions,
  truncateString,
} from "@markit/common.utils";
import SingleCustomDataItem from "./SingleCustomDataItem";
import { useOnMount } from "../../utils/useOnMount";
import {
  getNumEventMassTextsToUser,
  getNumMassTextsToUser,
} from "../../utils/textingUtils";
import { EmptySearchState } from "../EmptyStates/EmptySearchState";
import { Divider } from "@mui/material";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useDispatch } from "react-redux";
import {
  addSingleAudienceListMember,
  removeSingleAudienceListMember,
} from "../../redux/slices/accountSlice";
import ConfirmActionPopup, {
  BinaryConfirmActions,
} from "../Containers/ConfirmPopups/ConfirmActionPopup";
import LabelButton from "../Buttons/LabelButton";

export type RecentEventAnalytics = {
  event: Event;
  numTickets: number;
};

type FollowerProfileHeaderProps = {
  fullProfile: boolean;
  creatorUid: string;
  userData: AccountData;
  fullProfileOnPress?: () => void;
  closeProfilePreview?: () => void;
  addToListOnPress?: () => void;
  conversationOnPress: () => void;
  userTicket?: TicketV2 | undefined;
};

export const FollowerProfileHeader = memo(function FollowerProfileHeaderFn(
  props: FollowerProfileHeaderProps
) {
  const {
    fullProfile,
    creatorUid,
    userData,
    fullProfileOnPress,
    addToListOnPress,
    closeProfilePreview, // todo (jonathan): use this when add to list empty state is added on desktop
    conversationOnPress,
    userTicket,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { accountData, savedQuestions, audienceLists } = useSelector(
    (state: AppState) => state.account
  );

  const isAdmin = useSelector(
    (state: AppState) => state.account.accountData.isAdmin
  );

  const [followerData, setFollowerData] = useState<Follower | null | undefined>(
    null
  );
  const [followerEventData, setFollowerEventData] = useState<
    Event | null | undefined
  >(null);

  const [showMoreCustomData, setShowMoreCustomData] = useState(true);
  const [showMoreRecentEvents, setShowMoreRecentEvents] = useState(true);
  const [showMoreJoinedAudienceLists, setShowMoreJoinedAudienceLists] =
    useState(true);

  const [allFormResponses, setAllFormResponses] = useState<
    (SavedFormResponse | undefined)[]
  >([]);
  const [hostEssentialQuestion, setHostEssentialQuestion] = useState<
    SavedFormQuestion | undefined
  >(); // used if a cohost or other event role is viewing profile preview
  const [essentialFormResponse, setEssentialFormResponse] = useState<
    SavedFormResponse | null | undefined
  >(null);
  const [recentEventsData, setRecentEventsData] = useState<
    RecentEventAnalytics[]
  >([]);
  const [joinedAudienceLists, setJoinedAudienceLists] = useState<
    AudienceList[]
  >([]);
  // const [revenue, setRevenue] = useState<number>(0);
  // const [checkIns, setCheckIns] = useState<number>(0);
  const [numEventTexts, setNumEventTexts] = useState<number>(0);
  const [numMassTexts, setNumMassTexts] = useState<number>(0);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [isFavorited, setIsFavorited] = useState<boolean | null>(null);
  const [confirmFavoriteAlert, setConfirmFavoriteAlert] =
    useState<BinaryConfirmActions>(BinaryConfirmActions.NONE);

  const styles = {
    aligned: {
      alignItems: "center",
      display: "flex",
    },

    analyticsPanel: {
      border: `1.5px solid ${theme.DividerColor.borderColor}`,
      borderRadius: 8,
      padding: 10,
      marginBottom: 14,
    },

    emptyEventBox: {
      width: 50,
      height: 50,
      borderRadius: 8,
      backgroundColor: Colors.GRAY8,
    },

    emptyEventText: {
      justifyContent: "center",
      alignItems: "center",
      left: 0,
      right: 0,
    },

    favoriteButton: {
      width: 28,
      height: 28,
      borderRadius: 100,
      backgroundColor: isFavorited ? Colors.BLACK : Colors.WHITE,
      cursor: "pointer",
    },

    headerItems: {
      justifyContent: "space-between",
    },

    headerTitle: {
      marginLeft: 14,
      fontSize: 14,
    },

    largeNumber: {
      fontSize: 24,
      fontWeight: "500",
    },

    listAction: {
      width: 28,
      height: 28,
      borderRadius: 28 / 2,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    profileImage: {
      width: 50,
      height: 50,
      borderRadius: 50 / 2,
      overflow: "hidden",
      backgroundColor: theme.EmptyBG.backgroundColor,
    },

    profileBox: {
      justifyContent: "space-between",
      marginBottom: 14,
      paddingInline: 14,
      paddingBlock: 10,
      borderRadius: 12,
    },

    sectionContainer: {
      marginTop: 4,
      marginBottom: 14,
      paddingInline: 14,
      paddingBlock: 14,
      borderRadius: 12,
    },

    botSectionContainer: {
      marginTop: 4,
      paddingInline: 14,
      paddingBlock: 8,
      borderRadius: 12,
    },

    subHeaderTitle: {
      fontSize: 14,
      fontWeight: "500",
    },

    valuesRow: {
      justifyContent: "space-between",
      maxWidth: "80%",
    },
  };

  const notHost = useMemo(
    () => creatorUid !== accountData.uid,
    [accountData.uid, creatorUid]
  );

  const totalTickets = useMemo(
    () => recentEventsData.reduce((acc, curr) => acc + curr.numTickets, 0),
    [recentEventsData]
  );

  const privateAudienceLists = useMemo(
    () =>
      joinedAudienceLists.filter(
        (list) => list.visibility === AudienceListVisibility.PRIVATE
      ),
    [joinedAudienceLists]
  );

  const publicAudienceLists = useMemo(
    () =>
      joinedAudienceLists.filter(
        (list) => list.visibility === AudienceListVisibility.PUBLIC
      ),
    [joinedAudienceLists]
  );

  const favoriteList = useMemo(
    () => audienceLists.find((list) => list.name === FAVORITES_LIST_NAME),
    [audienceLists]
  );

  // need to use hostEssential question if this is a cohost or other event role viewing the profile preview on an event
  const essentialQuestion = useMemo(
    () =>
      !notHost
        ? savedQuestions.find((question) => question.isEssential)
        : hostEssentialQuestion,
    [hostEssentialQuestion, notHost, savedQuestions]
  );

  const savedQuestionsToShow = useMemo(
    () =>
      sortSavedQuestions(savedQuestions, []).filter((question) =>
        essentialQuestion ? essentialQuestion.id !== question.id : true
      ),
    [essentialQuestion, savedQuestions]
  );

  useOnMount(() => {
    (async () => {
      // look for essential question if not host
      let currHostEssentialQuestion: SavedFormQuestion | undefined;
      if (notHost) {
        currHostEssentialQuestion = await fetchUserEssentialQuestion(
          creatorUid
        );
        setHostEssentialQuestion(currHostEssentialQuestion);
      }

      const [
        followersData,
        essentialResponse,
        allResponses,
        recentEvents,
        foundAudienceLists,
        eventTextsToUser,
        massTextsToUser,
      ] = await Promise.all([
        fetchUserFollowerData(creatorUid, userData.uid),
        essentialQuestion || currHostEssentialQuestion
          ? fetchUserEssentialFormResponse(
              creatorUid,
              userData.uid,
              essentialQuestion
                ? essentialQuestion.id
                : currHostEssentialQuestion
                ? currHostEssentialQuestion.id
                : ""
            )
          : undefined,
        fullProfile
          ? fetchMultipleUserFormResponses(
              creatorUid,
              userData.uid,
              savedQuestionsToShow.map((question) => question.id)
            )
          : [],
        fetchMostRecentUserAttendedEvents(
          creatorUid,
          userData.uid,
          fullProfile ? undefined : 2
        ),
        fetchUserJoinedAudienceLists(
          creatorUid,
          userData.uid,
          fullProfile ? undefined : 2
        ),
        fullProfile ? getNumEventMassTextsToUser(creatorUid, userData.uid) : 0,
        fullProfile ? getNumMassTextsToUser(creatorUid, userData.uid) : 0,
      ]);
      setFollowerData(followersData?.follower);
      setFollowerEventData(followersData?.event);
      setEssentialFormResponse(essentialResponse);
      setAllFormResponses(allResponses); // only used on full profile
      setRecentEventsData(recentEvents);
      setJoinedAudienceLists(foundAudienceLists);
      setNumEventTexts(eventTextsToUser);
      setNumMassTexts(massTextsToUser);

      // todo (jonathan): for revenue geneating logic
      // const allCreatorEvents = events.filter(
      //   event => event.createdBy === accountData.uid
      // );
      // get number of events attended + tickets purchased in tandem
      // const userTickets = await getAllUsersTickets(
      //   event.id,
      //   userData.uid
      // );
      // if (userTickets && userTickets.length > 0) {
      //   userEventsAttended += 1;
      //   userTicketsPurchased += userTickets.length;
      //   for (const ticket of userTickets) {
      //     userRevenue += ticket.amountPaid;
      //     userCheckIns += Number(ticket.scanned);
      //   }
      // }
      // setRevenue(Math.round(userRevenue * 100) / 100); // round to 2 decimals
      // setCheckIns(userCheckIns);
    })();
  });

  useEffect(() => {
    (async () => {
      // Live set the favorites list state
      const isFavorited = await checkAudienceListMembership(
        userData.uid,
        favoriteList ? favoriteList.id : "",
        accountData.uid
      );
      setIsFavorited(isFavorited);
    })();
  }, [accountData.uid, favoriteList, userData.uid]);

  const followedFrom = useMemo(
    () =>
      followerData === null
        ? "Loading..."
        : followerData === undefined
        ? "Not Following"
        : followerData && followerData.followType !== FollowType.UNATTRIBUTED
        ? followerData.followType === FollowType.PROFILE
          ? "Followed Profile On"
          : followerData.followType === FollowType.EVENT
          ? `Went to Event${
              followerEventData ? ": " + followerEventData.title : ""
            }`
          : followerData.followType === FollowType.LINK
          ? `Submitted to Link${
              followerEventData ? ": " + followerEventData.title : ""
            }`
          : followerData.followType === FollowType.UPLOAD
          ? `Uploaded from ${
              followerData.spreadsheetData.fileName ?? "Import Contacts"
            }`
          : followerData.followType === FollowType.EVENTBRITE
          ? "Imported from Eventbrite"
          : "Followed On" // other
        : "Followed On",
    [followerData, followerEventData]
  );

  const followedOn = useMemo(
    () =>
      followerData === null
        ? "Loading..."
        : followerData === undefined
        ? "Not Following"
        : followerData && followerData.followType !== FollowType.UNATTRIBUTED
        ? GetDateWithTime(followerData.createdAt)
        : "Prior to Tracking",
    [followerData]
  );

  const pressFavorite = useCallback(() => {
    setFavoriteLoading(true);
    if (favoriteList) {
      if (isFavorited) {
        dispatch(
          removeSingleAudienceListMember(creatorUid, favoriteList, userData.uid)
        );
        setIsFavorited(false);
        setConfirmFavoriteAlert(BinaryConfirmActions.NEGATIVE);
      } else {
        mixpanel.track("Added Follower To Favorites List", {
          uid: creatorUid,
        });
        dispatch(
          addSingleAudienceListMember(creatorUid, favoriteList, userData.uid)
        );
        setIsFavorited(true);
        setConfirmFavoriteAlert(BinaryConfirmActions.AFFIRMATIVE);
      }
    }
  }, [isFavorited, favoriteList, dispatch, creatorUid, userData.uid, mixpanel]);

  const emptyEventsState = (isLoading: boolean) => {
    return (
      <div>
        <div className="AlignedRow" style={{ position: "relative" }}>
          <div className="ProfilePreviewEmptyEventBox" />
          <span className="ProfilePreviewEmptyEventText">
            {isLoading ? "Loading..." : "No Events to Show"}
          </span>
        </div>
        <Divider style={{ marginBlock: 14 }} />
      </div>
    );
  };

  const emptyListsState = (isLoading: boolean) => {
    return (
      <div style={{ marginTop: 5 }}>
        <EmptySearchState
          altTextColor={Colors.GRAY2}
          altBottomPadding={5}
          mainText={isLoading ? "Loading..." : "No List to Show"}
        />
        <Divider style={{ marginBlock: 14 }} />
      </div>
    );
  };

  const renderSection = (
    title: string,
    onPress: () => void,
    body: JSX.Element
  ) => {
    return (
      <div
        //   onClick={onPress} // todo (jonathan): disabled until we introduce new individual mass text analytics
        style={{
          ...styles.sectionContainer,
          backgroundColor: theme.PrimaryBG.backgroundColor,
          //   cursor: "pointer",
        }}
      >
        <div
          style={{
            ...styles.headerItems,
            ...styles.aligned,
            flexDirection: "row",
          }}
        >
          <span style={{ fontWeight: "600", fontSize: 14 }}>{title}</span>
          <div style={{ ...styles.aligned, flexDirection: "row" }}>
            <span style={{ fontWeight: "600", fontSize: 14 }}>All Data</span>
            <Icon
              icon="mdi:chevron-right"
              height={26}
              color={theme.SubText.color}
            />
          </div>
        </div>
        <Divider style={{ marginBlock: 14 }} />
        {body}
      </div>
    );
  };

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", maxHeight: "87vh" }} // hard code height to get overflow scrolling working
      >
        <div
          style={{
            ...styles.profileBox,
            ...styles.aligned,
            flexDirection: "row",
            backgroundColor: theme.PrimaryBG.backgroundColor,
          }}
        >
          <div
            style={{
              ...styles.aligned,
              flexDirection: "row",
            }}
          >
            {userData && userData.profilePicURL ? (
              <img
                src={userData.profilePicURL}
                alt="ProfilePicURL"
                style={styles.profileImage}
              />
            ) : (
              <div
                style={{
                  ...styles.profileImage,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InitialsPic fullName={userData?.fullName} />
              </div>
            )}
            <div style={{ paddingLeft: 14 }}>
              <div style={{ marginBottom: fullProfile ? 0 : 4 }}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "500",

                    ...theme.PrimaryText,
                  }}
                >
                  {userData?.fullName || "Markit User"}
                </span>
              </div>
              <div className="AlignedRow">
                {!fullProfile && (!notHost || isAdmin) ? (
                  <div
                    style={{
                      ...styles.aligned,
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onClick={fullProfileOnPress}
                  >
                    <span
                      style={{
                        color: Colors.BLUE5,
                        fontSize: 14,
                        fontWeight: "500",
                      }}
                    >
                      Full Profile
                    </span>
                    <Icon
                      icon="mdi:chevron-right"
                      height={16}
                      color={Colors.BLUE5}
                    />
                  </div>
                ) : null}
                {notHost ? (
                  <span
                    style={{
                      color: theme.SubText.color,
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                  >
                    {`Viewing as ${
                      isAdmin ? "Admin" : friendlyRoleName(userTicket)
                    } `}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="AlignedRow" style={{ gap: 10 }}>
            {!notHost ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {fullProfile ? (
                  <BlackHoverButton
                    leftIconName="ion:chatbubble-ellipses-outline"
                    leftIconSize={16}
                    onPress={conversationOnPress}
                    altBackgroundColor={Colors.WHITE}
                    containerStyles={styles.listAction}
                  />
                ) : null}
              </div>
            ) : null}
            {audienceLists.length > 0 && addToListOnPress ? (
              <BlackHoverButton
                leftIconName="mdi:playlist-plus"
                leftIconSize={16}
                onPress={addToListOnPress}
                altBackgroundColor={Colors.WHITE}
                containerStyles={styles.listAction}
              />
            ) : null}
            <div
              onClick={pressFavorite}
              className="Centering"
              style={{
                ...styles.favoriteButton,
                pointerEvents:
                  favoriteLoading || isFavorited === null ? "none" : "all",
              }}
            >
              <Icon
                icon={"ion:star-outline"}
                height={14}
                color={isFavorited ? Colors.WHITE : Colors.BLACK}
              />
            </div>
          </div>
        </div>
        <span style={{ ...theme.LabelText, ...styles.headerTitle }}>
          Essential
        </span>
        <div
          style={{
            ...styles.sectionContainer,
            backgroundColor: theme.PrimaryBG.backgroundColor,
          }}
        >
          {essentialQuestion && userData ? (
            <SingleCustomDataItem
              savedQuestion={essentialQuestion}
              formResponse={essentialFormResponse}
              userData={userData}
              pressable={fullProfile}
            />
          ) : null}
          <div style={{ marginBottom: 4 }}>
            <span style={{ ...theme.LabelText, fontSize: 14 }}>
              {followedFrom}
            </span>
          </div>
          <div>
            <span style={{ ...theme.PrimaryText, fontSize: 14 }}>
              {followedOn}
            </span>
          </div>
          <>
            <Divider style={{ marginBlock: 14 }} />
            <div style={{ marginBottom: 7 }}>
              <span style={{ ...theme.LabelText, fontSize: 14 }}>Status</span>
            </div>
            <div>
              <LabelButton
                color={
                  followerData &&
                  followerData.status === FollowerStatus.SUBSCRIBED
                    ? Colors.GREEN2
                    : Colors.RED3
                }
                backgroundColor={Colors.WHITE}
                text={followerData ? followerData.status : ""}
              />
            </div>
          </>
          {fullProfile ? (
            <>
              <Divider style={{ marginBlock: 14 }} />
              <div style={{ marginBottom: 4 }}>
                <span style={{ ...theme.LabelText, fontSize: 14 }}>
                  Follow Source
                </span>
              </div>
              <div>
                <span style={{ ...theme.PrimaryText, fontSize: 14 }}>
                  {followerData ? followerData.followSourceType : "Loading..."}
                </span>
              </div>
            </>
          ) : null}
        </div>
        {fullProfile ? (
          <>
            <span style={{ ...theme.LabelText, ...styles.headerTitle }}>
              Custom Data
            </span>
            <div
              style={{
                ...styles.sectionContainer,
                backgroundColor: theme.PrimaryBG.backgroundColor,
              }}
            >
              {fullProfile && userData && savedQuestionsToShow.length
                ? savedQuestionsToShow
                    .slice(
                      0,
                      savedQuestionsToShow.length > 8 && showMoreCustomData
                        ? 8
                        : savedQuestionsToShow.length
                    )
                    .map((question, index) => (
                      <div key={index}>
                        <SingleCustomDataItem
                          savedQuestion={question}
                          formResponse={allFormResponses[index]}
                          userData={userData}
                          pressable
                          lastItem={savedQuestionsToShow.length === index + 1}
                        />
                      </div>
                    ))
                : null}
              {savedQuestionsToShow.length > 8 && showMoreCustomData ? (
                <div
                  onClick={() => setShowMoreCustomData(!showMoreCustomData)}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{
                      color: Colors.BLUE5,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Show More...
                  </span>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        <span style={{ ...theme.LabelText, ...styles.headerTitle }}>
          {fullProfile ? "Events" : "Recent Events"}
        </span>
        <div
          style={{
            ...styles.sectionContainer,
            backgroundColor: theme.PrimaryBG.backgroundColor,
          }}
        >
          {recentEventsData ? (
            recentEventsData.length !== 0 ? (
              <>
                {fullProfile ? (
                  <>
                    <div
                      style={{
                        ...styles.analyticsPanel,
                        ...styles.aligned,
                        flexDirection: "row",
                      }}
                    >
                      <div className="ColumnNormal">
                        <span style={styles.largeNumber}>
                          {recentEventsData.length}
                        </span>
                        <span style={{ ...theme.LabelText, fontSize: 12 }}>
                          Total Events
                        </span>
                      </div>
                      <div className="ColumnNormal" style={{ marginLeft: 24 }}>
                        <span style={styles.largeNumber}>{totalTickets}</span>
                        <span style={{ ...theme.LabelText, fontSize: 12 }}>
                          Total Tickets
                        </span>
                      </div>
                    </div>
                    <span
                      style={{ ...theme.PrimaryText, ...styles.subHeaderTitle }}
                    >
                      Recent Events
                    </span>
                    <Divider style={{ marginBlock: 14 }} />
                  </>
                ) : null}
                {recentEventsData
                  .slice(
                    0,
                    recentEventsData.length > 3 && showMoreRecentEvents
                      ? 3
                      : recentEventsData.length
                  )
                  .map((data, index) => (
                    <div key={data.event.id} className="ColumnNormal">
                      <div style={{ ...styles.aligned, flexDirection: "row" }}>
                        <img
                          src={data.event.photoURL}
                          alt="EventPhotoURL"
                          style={{ width: 50, height: 50, borderRadius: 4 }}
                        />
                        <div style={{ paddingLeft: 10 }}>
                          <div style={{ marginBottom: 4 }}>
                            <span
                              className="OneLineText"
                              style={{
                                ...theme.PrimaryText,
                                fontSize: 14,
                                fontWeight: "500",
                              }}
                            >
                              {data.event.title}
                            </span>
                          </div>
                          <div>
                            <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                              {data.numTickets} Ticket
                              {data.numTickets !== 1 ? "s" : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      {(!fullProfile &&
                        (recentEventsData.length < 2 ||
                          index !== recentEventsData.length - 1)) ||
                      (fullProfile && index !== recentEventsData.length - 1) ? (
                        <Divider style={{ marginBlock: 14 }} />
                      ) : null}
                    </div>
                  ))}
                {recentEventsData.length > 3 && showMoreRecentEvents ? (
                  <div
                    onClick={() =>
                      setShowMoreRecentEvents(!showMoreRecentEvents)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      style={{
                        color: Colors.BLUE5,
                        fontSize: 16,
                        fontWeight: "500",
                      }}
                    >
                      Show More...
                    </span>
                  </div>
                ) : null}
              </>
            ) : (
              emptyEventsState(false)
            )
          ) : (
            emptyEventsState(true)
          )}
        </div>
        <span style={{ ...theme.LabelText, ...styles.headerTitle }}>Lists</span>
        <div
          style={{
            ...styles.botSectionContainer,
            backgroundColor: theme.PrimaryBG.backgroundColor,
          }}
        >
          {joinedAudienceLists ? (
            joinedAudienceLists.length !== 0 ? (
              <>
                {fullProfile ? (
                  <>
                    <div className="AlignedRow" style={styles.analyticsPanel}>
                      <div className="ColumnNormal">
                        <span style={styles.largeNumber}>
                          {joinedAudienceLists.length}
                        </span>
                        <span style={{ ...theme.LabelText, fontSize: 12 }}>
                          Joined
                        </span>
                      </div>
                      <div className="ColumnNormal" style={{ marginLeft: 24 }}>
                        <span style={styles.largeNumber}>
                          {publicAudienceLists.length}
                        </span>
                        <span style={{ ...theme.LabelText, fontSize: 12 }}>
                          Public
                        </span>
                      </div>
                      <div className="ColumnNormal" style={{ marginLeft: 24 }}>
                        <span style={styles.largeNumber}>
                          {privateAudienceLists.length}
                        </span>
                        <span style={{ ...theme.LabelText, fontSize: 12 }}>
                          Private
                        </span>
                      </div>
                    </div>
                    <span
                      style={{ ...theme.PrimaryText, ...styles.subHeaderTitle }}
                    >
                      Recent Lists
                    </span>
                    <Divider style={{ marginBlock: 14 }} />
                  </>
                ) : null}
                {joinedAudienceLists
                  .slice(
                    0,
                    joinedAudienceLists.length > 3 &&
                      showMoreJoinedAudienceLists
                      ? 3
                      : joinedAudienceLists.length
                  )
                  .map((list, index) => (
                    <div key={list.id}>
                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                        <div style={{ marginBottom: 4 }}>
                          <span
                            style={{
                              fontSize: 14,
                              fontWeight: "500",
                              ...theme.PrimaryText,
                            }}
                          >
                            {truncateString(list.name, 50, "...")}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontSize: 12, ...theme.LabelText }}>
                            {list.visibility === AudienceListVisibility.PUBLIC
                              ? "Public"
                              : "Private"}
                          </span>
                        </div>
                      </div>
                      {(!fullProfile &&
                        (joinedAudienceLists.length < 2 ||
                          index !== joinedAudienceLists.length - 1)) ||
                      (fullProfile &&
                        index !== joinedAudienceLists.length - 1) ? (
                        <Divider style={{ marginBlock: 14 }} />
                      ) : null}
                    </div>
                  ))}
                {joinedAudienceLists.length > 3 &&
                showMoreJoinedAudienceLists ? (
                  <div
                    onClick={() =>
                      setShowMoreJoinedAudienceLists(
                        !showMoreJoinedAudienceLists
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      style={{
                        color: Colors.BLUE5,
                        fontSize: 16,
                        fontWeight: "500",
                      }}
                    >
                      Show More...
                    </span>
                  </div>
                ) : null}
              </>
            ) : (
              emptyListsState(false)
            )
          ) : (
            emptyListsState(true)
          )}
        </div>
        {fullProfile
          ? renderSection(
              "Texting",
              () => {},
              <div
                style={{
                  ...styles.valuesRow,
                  ...styles.aligned,
                  flexDirection: "row",
                }}
              >
                <div className="ColumnNormal">
                  <span style={styles.largeNumber}>
                    {abbreviateNumber(numEventTexts + numMassTexts)}
                  </span>
                  <span style={{ color: Colors.GRAY1, fontSize: 12 }}>
                    Texts Received
                  </span>
                </div>
                <div className="ColumnNormal">
                  <span style={styles.largeNumber}>{numEventTexts}</span>
                  <span style={{ color: Colors.GRAY1, fontSize: 12 }}>
                    Event Texts
                  </span>
                </div>
                <div className="ColumnNormal">
                  <span style={styles.largeNumber}>{numMassTexts}</span>
                  <span style={{ color: Colors.GRAY1, fontSize: 12 }}>
                    Mass Texts
                  </span>
                </div>
              </div>
            )
          : null}
        {/* <div style={{ paddingVertical: 48 }}>
                <div style={{ ...styles.headerItems, flexDirection: "row" }}>
                  <div>
                    <div style={styles.headerText}>
                      <span style={{ fontWeight: '500', fontSize: 20 }}>
                        Attendee Revenue
                      </span>
                      <Icon
                        iconSet={IconSets.MaterialCommunityIcons}
                        name="currency-usd"
                        size={24}
                        style={{ color: Colors.GREEN1, marginLeft: 2 }}
                      />
                    </div>
                    <div style={{ marginTop: 2 }}>
                    <span style={{ color: Colors.GRAY1 }}>
                      Your revenue generated by this user
                    </span>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginBlock: 14 }} />
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: Colors.GRAY1 }}>Gross Revenue</span>
                  <span style={{ fontSize: 32 }}>${revenue}</span>
                </div>
              </div> */}
      </div>
      {confirmFavoriteAlert ? (
        <ConfirmActionPopup
          title="Added to Favorites"
          negativeTitle="Removed from Favorites"
          isNegative={confirmFavoriteAlert === BinaryConfirmActions.NEGATIVE}
          onDisappear={() => {
            setConfirmFavoriteAlert(BinaryConfirmActions.NONE);
            setFavoriteLoading(false);
          }}
        />
      ) : null}
    </>
  );
});
