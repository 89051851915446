import {
  memo,
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import "../../css/FullEvent/FullEventInputForm.css";
import "../../css/GlobalStyles.css";
import {
  CustomTicketV2,
  Event,
  PromoCode,
  TicketType,
} from "@markit/common.types";
import {
  getVisibleCustomTickets,
  isCustomTickets,
  isEventTicketsPaid,
  isFreeTickets,
  isPaidTickets,
} from "@markit/common.utils";
import { FormatCurrency } from "../../utils/FormatCurrency";
import { SelectCircleRow } from "../FullEventSubComponents/SelectCircleRow";
import {
  findFreeTicketAlias,
  getTicketDescription,
  getTicketsRemainingInGroup,
  ticketInStock,
} from "../../utils/eventUtils/eventUtils";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { TicketQuantityBox } from "../FullEventSubComponents/TicketQuantityBox";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { ErrorCode } from "@markit/common.api";
import { MixpanelContext } from "../../context/AnalyticsService";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import CustomTextField from "../../components/CustomTextField";

type FullEventTicketOptionsProps = {
  event: Event;
  isRedeemTicket: boolean;
  ticketForRedemption: CustomTicketV2 | undefined;
  setTicketForRedemption: (
    ticketForRedemption: CustomTicketV2 | undefined
  ) => void;
  promoCode: PromoCode | undefined;
  tixId: string;
  pCodeId: string;
  selectedCustomTicket: CustomTicketV2;
  selectedCustomTicketIndex: number;
  setSelectedCustomTicket: (selectedCustomTicket: CustomTicketV2) => void;
  ticketQuantity: number;
  setTicketQuantity: (ticketQuantity: number) => void;
  ticketFeesCents: number;
  totalAmountCents: number;
  discountedTicketPriceCents: number;
  numTicketsUserInGroups: number[];
  disabled: boolean;
  verifiedDetails: boolean;
  updatePromoCode: (promoCode: PromoCode) => void;
  updateTixId: (tixId: string) => void;
  setIsRedeemFreePromoCode: (isRedeemFreePromoCode: boolean) => void;
  isRedeemFreePromoCode: boolean;
  inputFormErrorPrompt: string | undefined;
};

export const FullEventTicketOptions = memo(function FullEventTicketOptionsFn(
  props: FullEventTicketOptionsProps
) {
  const {
    event,
    isRedeemTicket,
    ticketForRedemption,
    setTicketForRedemption,
    promoCode,
    tixId,
    pCodeId,
    selectedCustomTicket,
    selectedCustomTicketIndex,
    setSelectedCustomTicket,
    ticketQuantity,
    setTicketQuantity,
    ticketFeesCents,
    totalAmountCents,
    discountedTicketPriceCents,
    numTicketsUserInGroups,
    disabled,
    updatePromoCode,
    updateTixId,
    verifiedDetails,
    setIsRedeemFreePromoCode,
    isRedeemFreePromoCode,
    inputFormErrorPrompt,
  } = props;
  const { account } = useSelector(getAccountState);
  const { isRCA } = account;
  const mixpanel = useContext(MixpanelContext);

  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [showAmountDetails, setShowAmountDetails] = useState<boolean>(false);
  const [showPromoCodeInput, setShowPromoCodeInput] = useState<boolean>(false);
  const [promoCodeName, setPromoCodeName] = useState("");
  const [promoCodeValid, setPromoCodeValid] = useState<ErrorCode>(
    ErrorCode.NEUTRAL_PROMO
  );
  const [ticketsInStock, setTicketsInStock] = useState<boolean[] | null>(null);
  const [autoFirstTicketComplete, setAutoFirstTicketComplete] = useState(false);

  const { theme } = useTheme();

  const styles = {
    blueBold: { color: Colors.BLUE5, fontWeight: 500, fontSize: 12 },
  };

  const visibleTickets = useMemo(
    () => getVisibleCustomTickets(event.customTickets, ticketForRedemption?.id),
    [event.customTickets, ticketForRedemption?.id]
  );

  useEffect(() => {
    (async () => {
      const tixInStock = await Promise.all(
        visibleTickets.map(
          async (customTicket) => await ticketInStock(customTicket, event)
        )
      );
      setTicketsInStock(tixInStock);
      // sets the selected ticket to the first option where it's in stock and the user has not reached the maximum for that ticket type
      if (
        !disabled &&
        !autoFirstTicketComplete &&
        numTicketsUserInGroups.length
      ) {
        const firstAvailableTicket =
          visibleTickets[
            Math.max(
              tixInStock.findIndex((inStock) => inStock),
              visibleTickets.findIndex(
                (customTicket, index) =>
                  numTicketsUserInGroups[index] < customTicket.maxQuantity
              )
            )
          ];
        setSelectedCustomTicket(firstAvailableTicket);
        setAutoFirstTicketComplete(true);
      }

      const allLabels = await Promise.all(
        visibleTickets.map(async (customTicket) => {
          const ticketsRemainingInGroup = await getTicketsRemainingInGroup(
            customTicket,
            event
          );
          const labels = await getTicketDescription(
            customTicket,
            ticketsRemainingInGroup,
            event.hideResponses
          );
          return labels;
        })
      );
      setDescriptions(allLabels);
    })();
  }, [
    autoFirstTicketComplete,
    disabled,
    event,
    numTicketsUserInGroups,
    setSelectedCustomTicket,
    visibleTickets,
  ]);

  /**
   * Disable the specified ticket option if:
   * 1. No more tickets in stock for that group
   * 2. If the number of tickets the user is trying to get is greater than the total ticket quantity
   * 3. If user enters free promo code, disable other ticket options after entering
   * 4. If disabled is true from the passed in prop
   */
  const disabledTicket = useCallback(
    (customTicket: CustomTicketV2, index: number) => {
      return (
        (ticketsInStock && !ticketsInStock[index] && !isRedeemFreePromoCode) ||
        numTicketsUserInGroups[index] >= customTicket.maxQuantity ||
        (isRedeemFreePromoCode &&
          customTicket.id !== selectedCustomTicket.id) ||
        disabled
      );
    },
    [
      disabled,
      isRedeemFreePromoCode,
      numTicketsUserInGroups,
      selectedCustomTicket.id,
      ticketsInStock,
    ]
  );

  const soldOutCustomTicket = useCallback(
    (index: number) => {
      return ticketsInStock !== null && !ticketsInStock[index];
    },
    [ticketsInStock]
  );

  const isSelectedOption = useCallback(
    (customTicket: CustomTicketV2) =>
      customTicket.id === selectedCustomTicket.id ||
      customTicket.id === ticketForRedemption?.id,
    [selectedCustomTicket, ticketForRedemption?.id]
  );

  const multiTicketPrompt = useCallback(
    (customTicket: CustomTicketV2, index: number) => {
      const reachedMaxTickets =
        numTicketsUserInGroups[index] >= customTicket.maxQuantity;

      return (
        <>
          {reachedMaxTickets ? (
            <span style={theme.SubText} className="MultiTicketPrompt">
              *You have the max number of tickets{" "}
              <span style={styles.blueBold}>
                ({numTicketsUserInGroups[index]})
              </span>{" "}
              for this tier.
            </span>
          ) : numTicketsUserInGroups[index] > 0 ? (
            <span style={theme.SubText} className="MultiTicketPrompt">
              *You have{" "}
              <span style={styles.blueBold}>
                {numTicketsUserInGroups[index]}
              </span>{" "}
              out of a maximum of{" "}
              <span style={styles.blueBold}>
                {visibleTickets[index].maxQuantity}
              </span>{" "}
              tickets for this tier.
            </span>
          ) : null}
        </>
      );
    },
    [numTicketsUserInGroups, styles.blueBold, theme.SubText, visibleTickets]
  );

  const checkPromoCode = useCallback(
    async (promoCodeName) => {
      const foundPromoCode = event.promoCodes.find(
        (code) => code.alias === promoCodeName.toUpperCase()
      );
      if (foundPromoCode) {
        if (
          foundPromoCode.maxUseCount !== undefined
            ? foundPromoCode.usedCount < foundPromoCode.maxUseCount
            : foundPromoCode.redeemedBy === "" ||
              foundPromoCode.redeemedBy === "unlimited" ||
              foundPromoCode.redeemedBy === "pending"
        ) {
          updatePromoCode(foundPromoCode);
          setPromoCodeValid(ErrorCode.VALID_PROMO);
          mixpanel.track("Webapp: Applied Promo Code Successfully", {
            event_id: event.id,
            promo_code: foundPromoCode.id,
          });
        } else if (foundPromoCode.usedCount >= foundPromoCode.maxUseCount) {
          setPromoCodeValid(ErrorCode.REACHED_MAX_USE);
        } else {
          setPromoCodeValid(ErrorCode.ALREADY_USED);
        }
      } else {
        const foundFreeTicket = await findFreeTicketAlias(
          event.id,
          promoCodeName.toUpperCase()
        );
        if (foundFreeTicket[0]) {
          const foundCustomTicket = event.customTickets.find(
            (cTix) => cTix.id === foundFreeTicket[0]?.customTicketId
          );
          const foundCustomTicketIndex = event.customTickets.findIndex(
            (cTix) => cTix.id === foundFreeTicket[0]?.customTicketId
          );
          const reachedMaxSelectedTickets =
            foundCustomTicket &&
            numTicketsUserInGroups[foundCustomTicketIndex] >=
              foundCustomTicket.maxQuantity;
          if (foundFreeTicket[0].uid !== "") {
            setPromoCodeValid(ErrorCode.ALREADY_USED);
          } else if (reachedMaxSelectedTickets) {
            setPromoCodeValid(ErrorCode.ALREADY_USED);
          } else {
            // Successful found free ticket to redeem
            updateTixId(foundFreeTicket[0].id);
            setIsRedeemFreePromoCode(true);
            setPromoCodeValid(ErrorCode.VALID_PROMO);
            setTicketQuantity(1); // only can get one free ticket at a time
            if (foundCustomTicket) {
              setTicketForRedemption(foundCustomTicket);
              setSelectedCustomTicket(foundCustomTicket);
            }
          }
        } else {
          setPromoCodeValid(foundFreeTicket[1]);
        }
      }
    },
    [
      event.promoCodes,
      event.id,
      event.customTickets,
      updatePromoCode,
      mixpanel,
      numTicketsUserInGroups,
      updateTixId,
      setIsRedeemFreePromoCode,
      setTicketQuantity,
      setTicketForRedemption,
      setSelectedCustomTicket,
    ]
  );

  return (
    <div>
      <div className="ColumnNormal" style={{ gap: 7, marginBottom: 14 }}>
        <h4 className="NameAndNumber" style={theme.PrimaryText}>
          {isRedeemTicket && !isRedeemFreePromoCode
            ? "Redeeming Ticket"
            : numTicketsUserInGroups.some((numTickets) => numTickets > 0)
            ? "More Tickets"
            : "Tickets"}
        </h4>
        {!verifiedDetails ? (
          <div>
            <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
              Available after info entered
            </span>
          </div>
        ) : null}
        {inputFormErrorPrompt ? (
          <p style={theme.SubText} className="TicketsExceedCapacity">
            {inputFormErrorPrompt}
          </p>
        ) : null}
      </div>
      {isRedeemTicket && ticketForRedemption ? (
        <>
          <div
            className="SelectContainerOne"
            style={{
              opacity: disabled ? 0.3 : 1,
              pointerEvents: disabled ? "none" : "all",
              borderColor: theme.FocusedTextFieldBorder.borderColor,
              borderWidth: 2,
              backgroundColor: theme.SecondaryBG.backgroundColor,
            }}
          >
            <div>
              <div
                className="AlignedRow"
                style={{ marginBottom: 0, justifyContent: "space-between" }}
              >
                {isCustomTickets(event) ? (
                  <p className="TicketText" style={theme.PrimaryText}>
                    {ticketForRedemption.label}
                  </p>
                ) : isPaidTickets(event) ? (
                  <p className="TicketText" style={theme.PrimaryText}>
                    Paid Ticket
                  </p>
                ) : (
                  <p className="TicketText" style={theme.PrimaryText}>
                    Free Ticket
                  </p>
                )}
                {!isFreeTickets(event) ? (
                  <div
                    className="TicketText"
                    style={{ marginBlock: 5, ...theme.PrimaryText }}
                  >
                    $0
                    <span className="TicketStrike">
                      {FormatCurrency(ticketForRedemption.price / 100)}
                    </span>
                  </div>
                ) : null}
              </div>
              {multiTicketPrompt(ticketForRedemption, 0)}
              {descriptions[0] ? (
                <>
                  <span
                    className="TicketDescription"
                    style={{ color: "#858691" }}
                  >
                    {descriptions[0].substring(0, descriptions[0].indexOf("."))}
                  </span>
                  <span
                    className="TicketDescription"
                    style={{ color: "#858691" }}
                  >
                    {descriptions[0].substring(
                      descriptions[0].indexOf(".") + 1,
                      descriptions[0].length
                    )}
                  </span>
                </>
              ) : null}
              {ticketForRedemption.minQuantity > 1 ? (
                <div className="MinTicketText">
                  Min Quantity: {ticketForRedemption.minQuantity}
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            opacity: disabled ? 0.3 : 1,
            pointerEvents: disabled ? "none" : "all",
            display: "flex",
            flexDirection: "column",
            gap: "7px",
            marginTop: "14px",
          }}
          className="TicketOptions"
        >
          {visibleTickets.map((customTicket, index) => (
            <SelectCircleRow
              key={index}
              isDisabled={disabledTicket(customTicket, index)}
              isSoldOut={soldOutCustomTicket(index)}
              onPress={() => {
                if (selectedCustomTicket.id !== customTicket.id) {
                  setSelectedCustomTicket(customTicket);
                  setTicketQuantity(customTicket.minQuantity);
                }
              }}
              title={customTicket.label}
              minimumQuantity={customTicket.minQuantity}
              priceTag={
                <div>
                  {promoCode && selectedCustomTicket.id === customTicket.id ? (
                    <span className="TicketPrice">
                      {FormatCurrency(
                        discountedTicketPriceCents / ticketQuantity / 100
                      )}
                      <span className="TicketStrike">
                        {FormatCurrency(customTicket.price / 100)}
                      </span>
                    </span>
                  ) : customTicket.type === TicketType.FREE ? (
                    <span className="TicketPrice">Free</span>
                  ) : customTicket.type === TicketType.REQUEST ? (
                    <span className="TickePrice">Request</span>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {tixId ? <span className="TicketPrice">$0</span> : null}
                      <span
                        className={
                          tixId ? "TicketStrike TicketPrice" : "TicketPrice"
                        }
                        style={{
                          color:
                            (ticketsInStock && !ticketsInStock[index]) ||
                            tixId !== ""
                              ? "gray"
                              : theme.PrimaryText.color,
                        }}
                      >
                        {FormatCurrency(customTicket.price / 100)}
                      </span>
                    </div>
                  )}
                </div>
              }
              multiTicketText={multiTicketPrompt(customTicket, index)}
              description={descriptions[index]}
              isSelected={isSelectedOption(customTicket)}
              rightIcon={
                isSelectedOption(customTicket) ? (
                  <div>
                    {!isRedeemTicket ? (
                      <TicketQuantityBox
                        ticketQuantity={ticketQuantity}
                        setTicketQuantity={setTicketQuantity}
                        customTicket={customTicket}
                        currTickets={
                          numTicketsUserInGroups[selectedCustomTicketIndex]
                        }
                      />
                    ) : null}
                  </div>
                ) : (
                  <div style={{ width: 100 }}></div>
                )
              }
            />
          ))}
        </div>
      )}
      {verifiedDetails && isEventTicketsPaid(event) ? (
        <div
          style={{
            opacity: disabled ? 0.3 : 1,
            pointerEvents: disabled ? "none" : "all",
          }}
        >
          {!showPromoCodeInput && !pCodeId ? (
            <button
              onClick={() => setShowPromoCodeInput(!showPromoCodeInput)}
              disabled={disabled}
              className="RectangleButton"
              style={{
                ...theme.SecondaryBG,
                cursor: "pointer",
                marginTop: 10,
                paddingBlock: 10,
                borderColor: theme.TextFieldBorder.borderColor,
                borderWidth: 1,
              }}
            >
              <p
                style={{
                  ...theme.PrimaryText,
                  fontWeight: "600",
                  fontSize: 14,
                  cursor: disabled ? "auto" : "pointer",
                }}
              >
                Redeem a Code
              </p>
            </button>
          ) : (
            <div className="PromoCodeInputView">
              <div className="PromoCodeInput">
                <CustomTextField
                  value={promoCodeName}
                  inputMode="text"
                  placeholder="Enter Code"
                  borderRadius={12}
                  onChange={(name: any) => {
                    setPromoCodeName(name.target.value);
                  }}
                  error={
                    promoCodeValid === ErrorCode.INVALID_PROMO ||
                    promoCodeValid === ErrorCode.ALREADY_USED ||
                    promoCodeValid === ErrorCode.REACHED_MAX_USE
                  }
                  endAdornment={
                    promoCodeValid === ErrorCode.VALID_PROMO ? (
                      <Icon
                        icon="ion:checkmark-circle"
                        height={20}
                        style={{
                          paddingTop: 1,
                          marginRight: 10,
                          color: Colors.GREEN2,
                        }}
                      />
                    ) : promoCodeValid === ErrorCode.INVALID_PROMO ||
                      promoCodeValid === ErrorCode.ALREADY_USED ||
                      promoCodeValid === ErrorCode.REACHED_MAX_USE ? (
                      <Icon
                        icon="ion:close-circle-outline"
                        height={20}
                        style={{
                          paddingTop: 1,
                          marginRight: 10,
                          color: Colors.RED3,
                        }}
                      />
                    ) : undefined
                  }
                  width="50%"
                />
                <span onClick={() => checkPromoCode(promoCodeName)}>
                  <div className="PromoCodeApplyView">
                    {promoCodeValid === ErrorCode.VALID_PROMO &&
                    ticketForRedemption ? (
                      <p style={theme.PrimaryText}>
                        -
                        {FormatCurrency(
                          (ticketForRedemption.price * ticketQuantity -
                            discountedTicketPriceCents) /
                            100
                        )}
                      </p>
                    ) : (
                      <p className="PromoCodeApplyText">Apply Promo</p>
                    )}
                  </div>
                </span>
              </div>
            </div>
          )}
          {selectedCustomTicket.type === TicketType.PAID ? (
            <div className="TicketTotalOptions" style={theme.PrimaryText}>
              <p className="TicketTotalAmount">
                Total
                <span
                  style={{ marginLeft: 5, marginTop: 5 }}
                  onClick={() => setShowAmountDetails(!showAmountDetails)}
                >
                  {showAmountDetails ? (
                    <FiChevronUp size={20} style={theme.PrimaryText} />
                  ) : (
                    <FiChevronDown size={20} style={theme.PrimaryText} />
                  )}
                </span>
              </p>
              {!disabled ? (
                <p
                  style={{
                    display: "inline",
                    fontWeight: 600,
                    fontSize: "20px",
                  }}
                >
                  {FormatCurrency(totalAmountCents / 100)}
                </p>
              ) : null}
            </div>
          ) : null}
          {!disabled && showAmountDetails ? (
            <>
              <div className="TicketTotalOptions">
                <p className="TicketSubDetails">Ticket Price</p>
                <p className="TicketSubDetails">
                  {FormatCurrency(discountedTicketPriceCents / 100)}
                </p>
              </div>
              <div className="TicketTotalOptions" style={{ marginTop: 10 }}>
                <p className="TicketSubDetails">Service Fee</p>
                <p className="TicketSubDetails">
                  {FormatCurrency(ticketFeesCents / 100)}
                </p>
              </div>
            </>
          ) : null}
        </div>
      ) : undefined}
    </div>
  );
});
