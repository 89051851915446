import "../../../css/GlobalStyles.css";
import {
  AccountData,
  Conversation,
  IMessage,
  TextTypes,
  TwilioMessageStatus,
  FollowerStatus,
} from "@markit/common.types";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { limit, onSnapshot, query, where } from "firebase/firestore";
import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../API";
import {
  getConversationState,
  updateChatConversationIndicator,
} from "../../../redux/slices/conversationSlice";
import { isUnreadConversation } from "./ConversationsSidebarPreview";
import {
  getUserConversationsRef,
  getUserData,
} from "../../../utils/FirebaseUtils";
import { Colors } from "../../../utils/colors";
import TextareaAutosize from "react-textarea-autosize";
import ProfilePic from "../../ProfilePic";
import { DotWave } from "@uiball/loaders";
import {
  censoredWordsDetected,
  detectedCensored,
  uniqueVals,
} from "@markit/common.utils";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { useLoadUserFollowList } from "../../../hooks/useLoadUserFollowList";
import { useOnMount } from "../../../utils/useOnMount";
import filter from "lodash.filter";
import { Icon } from "@iconify/react";
import ConversationsMessagesContainer from "./ConversationsMessagesContainer";
import ProfileItem from "../../ProfileItem";
import { fetchSingleConversation } from "../../../utils/FetchSingleData";
import ConversationAttachmentButton from "./ConversationAttachmentButton";
import AlertButtonWrapper from "../../Containers/AlertButtonWrapper";
import { generate } from "shortid";
import { saveMediaToFirestore } from "../../../utils/photoUtils";
import ConfirmActionModal from "../../Containers/ConfirmPopups/ConfirmActionModal";
import { unsubscribedUserAlertText } from "../../../utils/alertUtils";

type ConversationsMainViewProps = {
  conversationsMainUser: AccountData | undefined;
  setConversationsMainUser: (
    conversationsMainUser: AccountData | undefined
  ) => void;
  isNewConversation: boolean;
  setIsNewConversation: (isNewConversation: boolean) => void;
  profilePreviewOnPress: (item: AccountData) => void;
  profilePreviewVisible: boolean;
  newUserSearchTerm: string;
  setNewUserSearchTerm: (newUserSearchTerm: string) => void;
};

const ConversationsMainView = (props: ConversationsMainViewProps) => {
  const dispatch = useDispatch();
  const {
    conversationsMainUser,
    setConversationsMainUser,
    isNewConversation,
    setIsNewConversation,
    profilePreviewOnPress,
    profilePreviewVisible,
    newUserSearchTerm,
    setNewUserSearchTerm,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { account } = useSelector(getAccountState);
  const { conversations } = useSelector(getConversationState);
  const { accountData, followingAccountData, followersData } = account;
  const { userConversations } = conversations;
  const [messageCount, setMessageCount] = useState(15);
  // Activity indicator while message is being sent
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [fetchedAllMessages, setFetchedAllMessages] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messageToSend, setMessageToSend] = useState<string>("");
  const [loadingConversation, setLoadingConversation] = useState<boolean>(true);
  const [conversationData, setConversationData] = useState<
    Conversation | undefined | null
  >(null);
  const [alertText, setAlertText] = useState({ header: "", subHeader: "" });

  const [currentImage, setCurrentImage] = useState<string>("");
  const [confirmSendImage, setConfirmSendImage] = useState(false);

  const [cursorPosition, setCursorPosition] = useState(messageToSend.length);
  const input = document.getElementById("conversationmainmessage");
  input?.addEventListener("click", showposition);

  const styles = {
    profileViewButton: {
      gap: 3,
      cursor: "pointer",
      paddingBlock: 5,
      paddingInline: 8,
      borderRadius: 8,
      backgroundColor: Colors.GRAY12,
    },
  };

  function showposition(event: any) {
    setCursorPosition(event.target.selectionStart);
  }

  const followerData = useMemo(
    () =>
      followersData.find(
        (follower) =>
          conversationsMainUser && follower.uid === conversationsMainUser.uid
      ),
    [conversationsMainUser, followersData]
  );

  const {
    isLoading: isLoadingFollowers,
    fetchedUserData: fetchedFollowersUserData,
    loadUsers: loadFollowers,
    loadSearchResultsThrottled,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
  });

  useOnMount(() => {
    loadFollowers();
  });

  const ref = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !profilePreviewVisible
      ) {
        setNewUserSearchTerm("");
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [profilePreviewVisible, setNewUserSearchTerm]);

  const handleSearch = (text: string) => {
    setNewUserSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  const containsUser = useCallback((item: AccountData, query: string) => {
    return (
      item.fullName.toLowerCase().includes(query) && item.phoneNumber !== ""
    );
  }, []);

  const followersToShow: AccountData[] = useMemo(() => {
    if (newUserSearchTerm === "") {
      return [];
    }
    let userList: AccountData[] = fetchedFollowersUserData;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, newUserSearchTerm);
    });
    return userList.slice(0, 5);
  }, [fetchedFollowersUserData, containsUser, newUserSearchTerm]);

  const fetchConversationMessages = useCallback(
    async (increment: boolean) => {
      if (conversationsMainUser) {
        const foundConversation = await fetchSingleConversation(
          accountData.uid,
          conversationsMainUser.uid,
          userConversations,
          (conversation) => setConversationData(conversation)
        );

        if (foundConversation) {
          const response = await API.creatorText.fetchUserConversationMessages({
            conversationId: foundConversation.conversationSid,
            twilioUser: accountData,
            attendeeUser: conversationsMainUser,
            messageLimit: increment ? messageCount + 15 : messageCount,
          });
          const { messages } = response;
          if (messages.length === 0) {
            setFetchedAllMessages(true);
          }
          setMessageCount(increment ? messageCount + 15 : messageCount);
          const sortedMessages = messages.sort((x: IMessage, y: IMessage) => {
            return (
              new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
            );
          });
          setMessages(uniqueVals(sortedMessages));
        }
      }
    },
    [accountData, conversationsMainUser, messageCount, userConversations]
  );

  const sendImage = useCallback(async () => {
    let mediaUrl = "";
    if (currentImage !== "") {
      setIsSendingMessage(true);
      const url = await saveMediaToFirestore(
        currentImage,
        accountData.uid + "/textMedia/chats/" + generate()
      );
      mediaUrl = url;

      await API.creatorText
        .sendMessageToUser({
          twilioUser: accountData,
          attendeeUser: conversationsMainUser,
          message: "\u00A0", // Can't send an empty string as message so send blank character
          mediaUrl: mediaUrl,
        })
        .then(async () => {
          if (mixpanel) {
            mixpanel.track(
              "Conversation: Creator Sent Message To Participant",
              {
                recipient_id: conversationsMainUser?.uid ?? "",
                from: "webapp",
                message: "Attachment: 1 Image",
                mediaUrl: mediaUrl,
              }
            );
          }
          setIsSendingMessage(false);
          setCurrentImage("");
        })
        .catch((err) => {
          console.log(err.message);
          setIsSendingMessage(false);
          setCurrentImage("");
        });
    }
  }, [accountData, conversationsMainUser, currentImage, mixpanel]);

  const sendTextMessage = useCallback(async () => {
    if (detectedCensored(messageToSend)) {
      setAlertText({
        header: "Content Violation!",
        subHeader: `You used the following banned terms in your message: '${censoredWordsDetected(
          messageToSend
        )}'. Remove these terms and resend.`,
      });
      return;
    }
    setIsSendingMessage(true);
    setIsNewConversation(false);
    setMessageToSend("");
    await API.creatorText
      .sendMessageToUser({
        twilioUser: accountData,
        attendeeUser: conversationsMainUser,
        message: messageToSend,
        mediaUrl: "",
      })
      .then(async () => {
        if (mixpanel) {
          mixpanel.track("Conversation: Creator Sent Message To Participant", {
            distinct_id: accountData.uid,
            recipient_id: conversationsMainUser?.uid,
            message: messageToSend,
            from: "webapp",
          });
        }
        setIsSendingMessage(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    accountData,
    conversationsMainUser,
    messageToSend,
    mixpanel,
    setIsNewConversation,
  ]);

  const newIMessage = useCallback(
    (convoData: Conversation) => {
      const iMessage: IMessage = {
        _id: convoData.recentMessage.twilioMessageId,
        text: convoData.recentMessage.text ?? "",
        createdAt: new Date(convoData.recentTimestamp) ?? "",
        user: {
          _id: convoData.recentMessage.sentBy,
          name: followingAccountData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.fullName,
          avatar: followingAccountData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.profilePicURL,
        },
        type: TextTypes.CREATORMESSAGE,
        status: TwilioMessageStatus.INITIALIZED,
      };
      return iMessage;
    },
    [followingAccountData]
  );

  // Snapshot listener for any new messages added to the conversation thread
  useOnMount(() => {
    (async () => {
      if (conversationsMainUser) {
        const conversationsRef = getUserConversationsRef(accountData.uid);
        const query_ = query(
          conversationsRef,
          where("participantUids", "array-contains", conversationsMainUser.uid),
          limit(1)
        );

        const unsubscribe = onSnapshot(query_, (snapshot) => {
          if (snapshot.empty) {
            setConversationData(undefined);
            setLoadingConversation(false);
            return;
          }
          snapshot.docChanges().forEach(async (message, index) => {
            if (message.type === "added" || message.type === "modified") {
              const conversationData = snapshot.docs.map((doc) =>
                doc.data()
              )[0];
              setConversationData(conversationData);
              const iMessage: IMessage = newIMessage(conversationData);
              if (iMessage.text !== "") {
                setMessages((prev) => {
                  if (
                    conversationData.recentMessage.sentBy !== accountData.uid
                  ) {
                    const index = prev.findIndex((c) => c._id === iMessage._id);
                    if (index === -1) {
                      prev = prev.concat(iMessage);
                    } else {
                      prev.splice(index, 1, iMessage);
                    }
                  } else {
                    prev = prev.concat(iMessage);
                  }
                  return uniqueVals(prev, (message) => message._id.toString());
                });
              }
            }
          });
        });

        return () => unsubscribe();
      }
    })();
  });

  useEffect(() => {
    (async () => {
      await fetchConversationMessages(false);
      setLoadingConversation(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationsMainUser, conversationData]);

  useEffect(() => {
    (async () => {
      if (currentImage) {
        setConfirmSendImage(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);

  useEffect(() => {
    if (
      conversationData &&
      isUnreadConversation(conversationData.chatIndicator, accountData.uid)
    ) {
      console.log("Marking read...");
      dispatch(
        updateChatConversationIndicator(
          conversationData.conversationSid,
          accountData.uid,
          false
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const selectNewConversation = useCallback(
    async (item: AccountData) => {
      const conversationExists = userConversations.find(
        (convo) => convo.participantUids[0] === item.uid
      );
      const userExists = await getUserData(item.uid);
      setMessages([]);
      if (conversationExists) {
        setConversationsMainUser(userExists);
        setIsNewConversation(false);
        setNewUserSearchTerm("");
      } else {
        setNewUserSearchTerm(item.fullName);
        setConversationsMainUser(userExists);
      }
    },
    [
      setConversationsMainUser,
      setIsNewConversation,
      setNewUserSearchTerm,
      userConversations,
    ]
  );

  const renderProfileViewButton = (item: AccountData) => {
    return (
      <div
        className="AlignedRow"
        style={styles.profileViewButton}
        onClick={(e) => {
          profilePreviewOnPress(item);
          e.stopPropagation();
        }}
      >
        <span
          style={{
            color: Colors.GRAY3,
            fontSize: 13,
            fontWeight: "500",
          }}
        >
          View
        </span>
        <Icon
          icon="ion:eye-outline"
          height={16}
          style={{ marginTop: 2 }}
          color={Colors.GRAY3}
        />
      </div>
    );
  };

  if (loadingConversation) {
    return (
      <div style={{ padding: 20 }}>
        <DotWave size={28} speed={1} color={Colors.BLACK} />
      </div>
    );
  }

  return (
    <div>
      {/* Conversation Header */}
      <div className="AlignedRow ConversationsMainViewHeader">
        {isNewConversation && !conversationsMainUser ? (
          <div ref={ref} style={{ position: "relative", height: 50 }}>
            <div className="AlignedRow">
              <span className="AboutSubtitle">To:</span>
              <TextareaAutosize
                value={newUserSearchTerm}
                placeholder={""}
                onChange={(e) => handleSearch(e.target.value)}
                className="ConversationsInput"
                style={{ color: Colors.BLACK, fontSize: 14, fontWeight: "500" }}
                maxRows={1}
              />
            </div>
            {followersToShow.length > 0 ? (
              <div
                className="ColumnNormal"
                style={{
                  position: "absolute",
                  top: 50,
                  left: 20,
                  padding: 10,
                  borderRadius: 8,
                  minWidth: 340,
                  boxShadow: "0px 1px 6px 0px #b9b9b9",
                  backgroundColor: Colors.WHITE,
                }}
              >
                {followersToShow.map((follower) => {
                  return (
                    <div
                      onClick={() => selectNewConversation(follower)}
                      className="AlignedRowSpacedSelect ConversationSidebarItem"
                      style={{ borderRadius: 8, paddingRight: 7 }}
                    >
                      <div className="AlignedRow" style={{ gap: 10 }}>
                        <ProfilePic user={follower} size={40} />
                        <span
                          className="AboutSubtitle"
                          style={{ fontSize: 14 }}
                        >
                          {follower.fullName}
                        </span>
                      </div>
                      {renderProfileViewButton(follower)}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : conversationsMainUser ? (
          <div className="AlignedRow" style={{ height: 50, flexGrow: 1 }}>
            <ProfileItem
              profileItem={conversationsMainUser}
              onItemPress={() => profilePreviewOnPress(conversationsMainUser)}
              hideViewIcon
              rightIcon={
                <div
                  onClick={() => profilePreviewOnPress(conversationsMainUser)}
                  className="AlignedRow"
                  style={styles.profileViewButton}
                >
                  <span
                    style={{
                      color: Colors.GRAY3,
                      fontSize: 13,
                      fontWeight: "500",
                    }}
                  >
                    View
                  </span>
                  <Icon
                    icon="ion:eye-outline"
                    height={16}
                    style={{ marginTop: 2 }}
                    color={Colors.GRAY3}
                  />
                </div>
              }
            />
          </div>
        ) : null}
      </div>
      <hr style={{ margin: "auto" }} />
      {/* Conversation Messages */}
      <div style={{ height: "calc(100vh - 320px)", overflow: "scroll" }}>
        <ConversationsMessagesContainer
          userId={accountData.uid}
          messages={messages}
          conversationsMainUser={conversationsMainUser}
          fetchedAllMessages={fetchedAllMessages}
          fetchConversationMessages={(increment: boolean) =>
            fetchConversationMessages(increment)
          }
        />
      </div>
      {/* Message Input */}
      <div
        className="AlignedRowSpaced"
        style={{ marginTop: 10, marginBottom: 14 }}
      >
        <ConversationAttachmentButton
          message={messageToSend}
          setMessage={setMessageToSend}
          image={currentImage}
          setImage={setCurrentImage}
          cursorPosition={cursorPosition}
          conversationMainUserId={conversationsMainUser?.uid ?? ""}
        />
        <div className="ConversationsInputContainer">
          <TextareaAutosize
            id="conversationmainmessage"
            value={messageToSend}
            placeholder={"Send a message..."}
            onChange={(e) => {
              setCursorPosition(e.target.selectionStart);
              setMessageToSend(e.target.value);
            }}
            className="ConversationsInput HideScrollbar"
            maxRows={5}
          />
          <AlertButtonWrapper
            buttonComp={
              <div
                onClick={() => {
                  followerData &&
                  followerData.status !== FollowerStatus.SUBSCRIBED
                    ? setAlertText({
                        header: unsubscribedUserAlertText(followerData),
                        subHeader: "",
                      })
                    : sendTextMessage();
                }}
                style={{
                  pointerEvents:
                    isSendingMessage || messageToSend === "" ? "none" : "all",
                  cursor: "pointer",
                  alignSelf: isSendingMessage ? "center" : "flex-end",
                }}
              >
                {isSendingMessage ? (
                  <span
                    className="AboutSubtitle"
                    style={{
                      color: Colors.GRAY1,
                      cursor: "not-allowed",
                      marginRight: 18,
                    }}
                  >
                    Sending...
                  </span>
                ) : (
                  <div
                    className="CreatorChatSendButton"
                    style={{
                      backgroundColor:
                        messageToSend === "" ? Colors.GRAY1 : Colors.BLACK,
                      cursor: messageToSend === "" ? "not-allowed" : "pointer",
                    }}
                  >
                    <Icon
                      icon="ion:paper-plane"
                      height={20}
                      style={{
                        paddingRight: 2,
                        paddingTop: 2,
                        color: Colors.WHITE,
                      }}
                    />
                  </div>
                )}
              </div>
            }
            alertTextHeader={alertText.header}
            alertTextSubHeader={alertText.subHeader}
            clearAlert={() => setAlertText({ header: "", subHeader: "" })}
          />
        </div>
      </div>
      <ConfirmActionModal
        heading="Ready to Send?"
        confirmButtonText="Send"
        icon={<Icon icon="ion:paper-plane" height={40} />}
        hideModal={!confirmSendImage}
        setIsVisible={setConfirmSendImage}
        confirmOnPress={() => sendImage()}
        cancelOnPress={() => setCurrentImage("")}
      />
    </div>
  );
};

export default ConversationsMainView;
