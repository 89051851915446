import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import { MassTextItemPreview } from "./Items/MassTextItemPreview";
import { useEffect, useMemo, useState } from "react";
import { useLoadMassTextItems } from "../../../hooks/useLoadMassTextItems";
import { MassText } from "@markit/common.types";
import {
  getNumMassTexts,
  getNumScheduledMassTexts,
} from "../../../utils/textingUtils";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { getEventState } from "../../../redux/slices/eventSlice";
import filter from "lodash.filter";
import { useOnMount } from "../../../utils/useOnMount";
import { onSnapshot } from "../../../firebase";
import { getUserMassTextsRef } from "../../../utils/FirebaseUtils";
import StandardListContainer from "../../Containers/StandardListContainer";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import GridTableHeader from "../../Headers/GridTableHeader";
import ListTabs from "../../DropdownsAndTabs/ListTabs";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { EmptyStateFlatlist } from "../../EmptyStates/EmptyStateFlatlist";

export enum TextOption {
  ALL = "all",
  SENT = "sent",
  SCHEDULED = "scheduled",
}

const massTextHeaderLabels: string[] = [
  "Text Type",
  "Send Time",
  "Message",
  "Recipients",
  "Links",
];

type MassTextsPanelProps = {
  setSentAndScheduledTexts: (texts: number) => void;
  massTextPreviewOnPress: (massText: MassText) => void;
  tabValue: number;
  setMassTextsPopupPanelVisible: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
};

const MassTextsPanel = (props: MassTextsPanelProps) => {
  const {
    setSentAndScheduledTexts,
    massTextPreviewOnPress,
    tabValue,
    setMassTextsPopupPanelVisible,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { events: eventList } = useSelector(getEventState);
  const { events } = eventList;
  const [numSentTexts, setNumSentTexts] = useState<number>(0);
  const [numScheduledTexts, setNumScheduledTexts] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loadingMassTexts, setLoadingMassTexts] = useState(true);
  const [loadingScheduledTexts, setLoadingScheduledTexts] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [listValue, setListValue] = useState(0);

  const initialLaunchDate = new Date().toISOString();

  const {
    isFinished,
    isLoading,
    fetchedMassTextData,
    loadMassTextItems,
    loadMoreMassTextItems,
    addMassTextData,
  } = useLoadMassTextItems({
    userId: accountData.uid,
    windowSize: 30,
    scheduled: false,
  });

  const {
    isFinished: isScheduledFinished,
    isLoading: isScheduledLoading,
    fetchedMassTextData: fetchedScheduledData,
    loadMassTextItems: loadScheduledItems,
    loadMoreMassTextItems: loadMoreScheduledItems,
  } = useLoadMassTextItems({
    userId: accountData.uid,
    windowSize: 30,
    scheduled: true,
  });

  // Needed for when a new mass text is sent and need to update the fetched mass texts
  // The issue with just having the normal snapshot was that the query timestamp is set
  // to when the page loaded, so any mass text sent after that time would show up in scheduled vs. the sent
  useOnMount(() => {
    (async () => {
      const unsubscribe = onSnapshot(
        getUserMassTextsRef(accountData.uid),
        (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            const massText = change.doc.data();
            if (!massText.scheduled && massText.sentAt >= initialLaunchDate) {
              addMassTextData(massText, true);
            }
          });
        }
      );
      return unsubscribe;
    })();
  });

  useEffect(() => {
    (async () => {
      const [totalMassTexts, totalScheduledTexts] = await Promise.all([
        getNumMassTexts(accountData.uid),
        getNumScheduledMassTexts(accountData.uid),
      ]);
      setNumSentTexts(totalMassTexts);
      setNumScheduledTexts(totalScheduledTexts);
      setSentAndScheduledTexts(totalMassTexts + totalScheduledTexts);
      setLoading(false);
    })();
  }, [
    accountData.uid,
    addMassTextData,
    fetchedMassTextData,
    fetchedScheduledData,
    numSentTexts,
    setSentAndScheduledTexts,
  ]);

  useEffect(() => {
    if (loadingMassTexts && fetchedMassTextData.length === 0 && !isFinished) {
      loadMassTextItems();
      setLoadingMassTexts(false);
    }
    if (
      loadingScheduledTexts &&
      fetchedScheduledData.length === 0 &&
      !isScheduledFinished
    ) {
      loadScheduledItems();
      setLoadingScheduledTexts(false);
    }
  }, [
    fetchedMassTextData.length,
    fetchedScheduledData.length,
    isFinished,
    isScheduledFinished,
    loadMassTextItems,
    loadScheduledItems,
    loadingMassTexts,
    loadingScheduledTexts,
  ]);

  const contains = (massText: MassText, query: string) => {
    return (
      massText.message.toLowerCase().includes(query) ||
      massText.message.toLowerCase().includes(query)
    );
  };

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const currentListToShow: MassText[] = useMemo(() => {
    let massTextItems: MassText[] = [];
    massTextItems =
      listValue === 0 ? fetchedMassTextData : fetchedScheduledData;
    if (searchTerm !== "") {
      massTextItems = filter(massTextItems, (massText: MassText) => {
        return contains(massText, searchTerm);
      });
    }
    return massTextItems;
  }, [listValue, fetchedMassTextData, fetchedScheduledData, searchTerm]);

  const renderEmptyMassText = useMemo(() => {
    const isSentText = listValue === 0; // false = scheduled text list
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={isSentText ? "No Sent Texts" : "No Scheduled Texts"}
            description={
              isSentText
                ? "Your sent texts will show up here"
                : "Your scheduled texts will show up here"
            }
            icon={
              <Icon
                icon={isSentText ? "ion:paper-plane" : "ion:timer"}
                height={60}
                style={{ color: Colors.GRAY1 }}
              />
            }
            iconBox={70}
            btnText={"New Text"}
            onPress={() => {
              setMassTextsPopupPanelVisible(true);
            }}
            containerStyles={{ paddingTop: 100 }}
          />
        }
      />
    );
  }, [isLoading, listValue, searchTerm, setMassTextsPopupPanelVisible]);

  return (
    <TabPanel value={tabValue} index={1}>
      <StandardListContainer
        searchComp={
          <SearchBoxContainer
            placeholder={"Search Sent, Scheduled Texts..."}
            onChange={(e) => handleSearch(e.target.value)}
            containerStyles={{ marginTop: 0 }}
          />
        }
        subHeaderComp={
          <ListTabs
            tabLabels={["Sent", "Scheduled"]}
            tabNumbers={[
              loading ? -1 : numSentTexts,
              loading ? -1 : numScheduledTexts,
            ]}
            selectedValue={listValue}
            onChange={setListValue}
            containerStyles={{ paddingInline: 14, paddingBottom: 12 }}
            altColor={Colors.BLACK}
          />
        }
        gridHeaderComp={
          <GridTableHeader
            flexSections={[4, 4, 5, 3, 4]}
            sectionTitles={massTextHeaderLabels}
          />
        }
        listComp={
          <FlatList
            list={currentListToShow}
            renderItem={(item) => {
              const foundEvent = events.find(
                (event) => event.id === item.eventRefId
              );
              // to determine if scheduled text has been sent
              const date = new Date().toISOString();

              return (
                <MassTextItemPreview
                  userId={accountData.uid}
                  massTextItem={item}
                  foundEvent={foundEvent}
                  scheduledSent={item.scheduled && date > item.sentAt}
                  onPress={() => massTextPreviewOnPress(item)}
                />
              );
            }}
            renderWhenEmpty={() => renderEmptyMassText}
            hasMoreItems={
              (!isFinished && listValue === 0) ||
              (!isScheduledFinished && listValue === 1)
            }
            loadMoreItems={() => {
              if (!isFinished && listValue === 0 && !isLoading) {
                setTimeout(() => {
                  loadMoreMassTextItems();
                }, 50);
              } else if (
                !isScheduledFinished &&
                listValue === 1 &&
                !isScheduledLoading
              ) {
                setTimeout(() => {
                  loadMoreScheduledItems();
                }, 50);
              }
            }}
            paginationLoadingIndicator={() =>
              isLoading || isScheduledLoading ? (
                <CircularProgress
                  style={{
                    color: "#929292",
                    alignSelf: "center",
                    marginTop: "24px",
                    marginLeft: 14,
                  }}
                  size={30}
                />
              ) : null
            }
          />
        }
        isLoading={loading}
      />
    </TabPanel>
  );
};

export default MassTextsPanel;
