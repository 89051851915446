import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import ContactSupportButton from "../Buttons/ContactSupportButton";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import {
  IntegrationPhoneContact,
  IntegrationPhoneContactState,
} from "@markit/common.types";
import EventbriteIcon from "../IntegrationIcons/EventbriteIcon";
import ValidContactsPopupPanel from "../CreatorDashboard/CreatorProfile/ImportContacts/ValidContactsPopupPanel";

type IntegrationConfirmProps = {
  phoneContacts: IntegrationPhoneContact[];
  autoCollectPhone: boolean;
};

const IntegrationConfirm = (props: IntegrationConfirmProps) => {
  const { phoneContacts, autoCollectPhone } = props;
  const [contactsView, setContactsView] = useState(0);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    container: { paddingBlock: 14, backgroundColor: Colors.WHITE1 },
  };

  const phoneContactsGroup = useCallback(
    (state: IntegrationPhoneContactState) =>
      phoneContacts.filter((contact) => contact.state === state),
    [phoneContacts]
  );

  const contactsToShow = useMemo(() => {
    const contacts: { name: string; phoneNumber: string }[] =
      phoneContactsGroup(
        contactsView === 1
          ? IntegrationPhoneContactState.NEW
          : contactsView === 2
          ? IntegrationPhoneContactState.INVALID
          : IntegrationPhoneContactState.ALREADY_EXISTING
      ).map((row) => {
        return { name: row.name, phoneNumber: row.phoneNumber };
      });
    return contacts;
  }, [contactsView, phoneContactsGroup]);

  const contactsInfoRow = useCallback(
    (contactRow: number, rowValue: number) => {
      const rowName =
        contactRow === 1
          ? "New Valid Phone Number Contacts"
          : contactRow === 2
          ? "Contacts with No Phone Numbers"
          : "Already Existing Contacts";
      return (
        <div
          onClick={() => setContactsView(contactRow)}
          className="AlignedRowSpacedSelect"
          style={{
            paddingInline: 14,
            pointerEvents: rowValue === 0 ? "none" : "all",
          }}
        >
          <div className="ColumnNormalSelect" style={{ gap: 7 }}>
            <span
              className="bodySubtext"
              style={{
                fontWeight: 500,
                color:
                  contactRow === 1
                    ? rowValue === 0
                      ? Colors.RED3
                      : Colors.GREEN2
                    : Colors.BLACK,
              }}
            >
              {rowValue} {rowName}
            </span>
          </div>
          <Icon icon="ion:chevron-right" height={14} color={Colors.GRAY1} />
        </div>
      );
    },
    []
  );

  return (
    <>
      <div
        className="ColumnNormal Centering"
        style={{ gap: 40, paddingBlock: 30 }}
      >
        <span style={styles.headerText}>Confirm your sync details</span>
        <div className="ColumnNormal" style={{ gap: 40, width: 547 }}>
          {autoCollectPhone ? (
            <StandardBorderedContainer
              containerStyles={{ ...styles.container, paddingInline: 14 }}
            >
              <div className="AlignedRowSpaced">
                <div className="ColumnNormal" style={{ gap: 7, width: "70%" }}>
                  <span className="bodyMedium">
                    Automatically Collecting Attendee Phone Numbers
                  </span>
                  <span className="smallBodySubtext">
                    Automatically add a host question that collects my
                    attendee's phone numbers to all my upcoming Eventbrite
                    Events.
                  </span>
                </div>
                <Icon icon={"ion:checkmark-circle"} height={32} />
              </div>
            </StandardBorderedContainer>
          ) : null}
          <StandardBorderedContainer containerStyles={styles.container}>
            <div className="ColumnNormal">
              <div
                className="AlignedRow"
                style={{ paddingInline: 14, gap: 14 }}
              >
                <EventbriteIcon size={34} padding={10} />
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <div className="AlignedRow" style={{ gap: 10 }}>
                    <span className="sectionTitle">Eventbrite</span>
                    <span className="bodySubtext">Connected</span>
                  </div>
                  <span className="smallBodySubtext">
                    Seamlessly connect all your attendees and contacts to sync
                    with your Markit contacts and start texting.
                  </span>
                </div>
              </div>
              <HorizontalDivider altMargin={14} />
              {contactsInfoRow(
                1,
                phoneContactsGroup(IntegrationPhoneContactState.NEW).length
              )}
              {/* <HorizontalDivider altMargin={14} /> */}
              {/* {contactsInfoRow(
                2,
                phoneContactsGroup(IntegrationPhoneContactState.INVALID).length
              )} */}
              {phoneContactsGroup(IntegrationPhoneContactState.ALREADY_EXISTING)
                .length > 0 ? (
                <>
                  <HorizontalDivider altMargin={14} />
                  {contactsInfoRow(
                    3,
                    phoneContactsGroup(
                      IntegrationPhoneContactState.ALREADY_EXISTING
                    ).length
                  )}
                </>
              ) : null}
            </div>
          </StandardBorderedContainer>
        </div>

        <ContactSupportButton />
      </div>
      {contactsView > 0 ? (
        <ValidContactsPopupPanel
          contactsToShow={contactsToShow}
          contactsView={contactsView}
          setContactsView={setContactsView}
        />
      ) : null}
    </>
  );
};

export default IntegrationConfirm;
