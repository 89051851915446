import FlatList from "flatlist-react/lib";
import { Colors } from "../../utils/colors";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import StandardListContainer from "../Containers/StandardListContainer";
import GridTableHeader from "../Headers/GridTableHeader";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import { useOnMount } from "../../utils/useOnMount";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  AccountData,
  SpreadsheetStatus,
  FollowerStatus,
} from "@markit/common.types";
import filter from "lodash.filter";
import EventDashboardAction from "../EventDashboard/EventDashboardAction";
import ImportHistory from "../CreatorDashboard/CreatorProfile/ImportContacts/ImportHistory";
import { TabPanel } from "../FullEventSubComponents/TabPanel";
import ProfileSpreadsheetItem, {
  SPREADSHEET_USER_FLEX_SECTIONS,
} from "../DisplayItem/ProfileSpreadsheetItem";
import { EmptyStateFlatlist } from "../EmptyStates/EmptyStateFlatlist";
import { useLoadSpreadsheetUserList } from "../../hooks/useLoadSpreadsheetUserList";
import { useLiveUpdatingImportAnalytics } from "../../hooks/useLiveHooks/useLiveUpdatingImportAnalytics";

type UploadDataPanelProps = {
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  importOnPress: () => void;
  tabValue: number;
};

export const UploadDataPanel = (props: UploadDataPanelProps) => {
  const { setProfilePreviewSelected, importOnPress, tabValue } = props;
  const { accountData, appInitialized } = useSelector(getAccountState).account;
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [importHistoryVisible, setImportHistoryVisible] = useState(false);

  const styles = {
    optInHeader: {
      paddingTop: 6,
      paddingBottom: 18,
      paddingInline: 24,
      fontSize: 14,
      fontWeight: "500",
    },
  };

  const { liveTotalSubscribedImportContacts, loaded } =
    useLiveUpdatingImportAnalytics();

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadMoreUsers,
    loadUsers,
    loadSearchResultsThrottled,
    isLoading: isLoadingFollowers,
    isFinished: isFinishedLoadingFollowers,
  } = useLoadSpreadsheetUserList({
    userId: accountData.uid,
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
    status: SpreadsheetStatus.ACCEPTED,
  });

  useOnMount(() => {
    loadUsers();
    setLoadingFollowers(false);
  });

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text.toLowerCase());
  };

  const itemsToShow: AccountData[] = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userList: AccountData[] = [];
    userList = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [appInitialized, fetchedUserData, searchTerm, contains]);

  const importHistoryOnPress = useCallback(() => {
    setImportHistoryVisible(true);
  }, []);

  const renderItem = useCallback(
    (item: AccountData) => (
      <ProfileSpreadsheetItem
        item={item}
        setProfilePreviewSelected={setProfilePreviewSelected}
        fetchedFollowerData={fetchedFollowerData}
      />
    ),
    [fetchedFollowerData, setProfilePreviewSelected]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoadingFollowers}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title="Import Contacts"
            description="Import a CSV to build your audience"
            icon={
              <Icon icon="ion:cloud-upload" height={64} color={Colors.GRAY1} />
            }
            iconBox={84}
            btnText="New Import"
            onPress={importOnPress}
            containerStyles={{ paddingTop: 150 }}
          />
        }
      />
    );
  }, [importOnPress, isLoadingFollowers, searchTerm]);

  return (
    <TabPanel value={tabValue} index={3}>
      <div className="ColumnNormal" style={{ gap: 30, paddingBottom: 48 }}>
        <div className="AlignedRow" style={{ gap: 30 }}>
          <EventDashboardAction
            title={"Import History"}
            iconName={"ion:hourglass"}
            iconSize={31}
            onPress={importHistoryOnPress}
            visible={true}
            containerWidth={"100%"}
          />
          <EventDashboardAction
            title={"New Import"}
            iconName={"ion:cloud-upload"}
            iconSize={31}
            onPress={importOnPress}
            visible={true}
            containerWidth={"100%"}
          />
        </div>
        <StandardListContainer
          searchComp={
            <SearchBoxContainer
              placeholder="Search People"
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0 }}
            />
          }
          headerRightComp={
            <BlackHoverButton
              title="Import"
              iconName="ion:cloud-upload"
              permanentHover
              iconSize={14}
              onPress={importOnPress}
              containerStyles={{ paddingInline: 14, paddingBlock: 12 }}
            />
          }
          subHeaderComp={
            <span style={styles.optInHeader}>
              Opted-In ({loaded ? liveTotalSubscribedImportContacts : "--"})
            </span>
          }
          gridHeaderComp={
            <GridTableHeader
              flexSections={SPREADSHEET_USER_FLEX_SECTIONS}
              sectionTitles={["Name", "Upload Name", "Phone Number", "Status"]}
            />
          }
          listComp={
            <FlatList
              list={itemsToShow}
              keyExtractor={(item: AccountData) => item.uid}
              renderItem={renderItem}
              renderWhenEmpty={() => renderEmptyView}
              hasMoreItems={!isFinishedLoadingFollowers}
              loadMoreItems={() => {
                setTimeout(() => {
                  if (searchTerm.trim() === "") {
                    loadMoreUsers();
                  }
                }, 50); // The set index has a tiny delay
              }}
              paginationLoadingIndicator={() =>
                isLoadingFollowers && !isFinishedLoadingFollowers ? (
                  <CircularProgress
                    style={{
                      color: "#929292",
                      alignSelf: "center",
                      marginTop: "24px",
                      marginLeft: "14px",
                    }}
                    size={30}
                  />
                ) : null
              }
            />
          }
          isLoading={loadingFollowers}
        />
        {importHistoryVisible ? (
          <ImportHistory
            isVisible={importHistoryVisible}
            setIsVisible={setImportHistoryVisible}
          />
        ) : null}
      </div>
    </TabPanel>
  );
};
