import "../../../css/GlobalStyles.css";
import { useCallback, useState } from "react";
import { Colors } from "../../../utils/colors";
import { AccountData } from "@markit/common.types";
import ProfilePreviewModal from "../../../components/FollowerProfile/ProfilePreviewModal";
import ShareProfilePopupPanel from "../../../components/CreatorDashboard/CreatorProfile/ShareProfilePopupPanel";
import { PeoplePanel } from "../../../components/HomePage/PeoplePanel";
import { ListsPanel } from "../../../components/HomePage/ListsPanel";
import { UploadDataPanel } from "../../../components/HomePage/UploadDataPanel";
import ConversationsSidebarModal from "../../../components/CreatorDashboard/Conversations/ConversationsSidebarModal";
import { CreatorPanelTabs } from "../../../components/CreatorDashboard/CreatorPanelTabs";
import { greetingMessage, hasSubscription } from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useNavigate } from "../../../hooks/useNavigate";
import { useLocation } from "react-router-dom";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import { ImportantAnnouncementModal } from "../../../components/DisplayModal/ImportantAnnouncementModal";
import { IntegrationsPanel } from "../../../components/HomePage/IntegrationsPanel";
import { Icon } from "@iconify/react";
import { MarkitPlusModal } from "../../../components/Subscription/MarkitPlusModal";
import { AudiencePanel } from "../../../components/HomePage/AudiencePanel";

const HomePanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const { initialTabValue } = useLocation().state ?? {};
  const navigate = useNavigate();
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();
  const [conversationUser, setConversationUser] = useState<AccountData>();
  const [hideProfilePreview, setHideProfilePreview] = useState(false);
  const [shareProfileOpen, setShareProfileOpen] = useState(false);
  const [fullProfileOpen, setFullProfileOpen] = useState(false);
  const [tabValue, setTabValue] = useState(initialTabValue ?? 0);
  const [subModalVisible, setSubModalVisible] = useState(false);

  const navigateNewUpload = useCallback(() => {
    if (!hasSubscription(accountData)) {
      setSubModalVisible(true);
      return;
    }
    navigate("/import");
  }, [accountData, navigate]);

  const navigateShareProfile = useCallback(() => {
    setShareProfileOpen(true);
  }, []);

  return (
    <div>
      <div className="CreatorPanelContainer">
        <div className="AlignedRowSpaced">
          <h2>{greetingMessage()}</h2>
          <div className="AlignedRow" style={{ gap: 14 }}>
            {tabValue !== 4 && !accountData.eventbriteInfo.connectedOn ? (
              <div
                onClick={() => setTabValue(4)}
                className="AlignedRowSelect"
                style={{ gap: 7 }}
              >
                <Icon icon="fa-solid:plug" height={12} />
                <span className="bodyMedium">Integrations</span>
              </div>
            ) : null}
            <MarkitPlusActionButton mainAction />
          </div>
        </div>
        <CreatorPanelTabs
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabNames={["Home", "Audience", "Lists", "Import", "Integrations"]}
        />
      </div>
      <div
        className="CreatorPanelContainer"
        style={{ minHeight: "80vh", backgroundColor: Colors.WHITE1 }}
      >
        <PeoplePanel
          setProfilePreviewSelected={setProfilePreviewSelected}
          shareProfileOnPress={navigateShareProfile}
          importOnPress={navigateNewUpload}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
        <AudiencePanel
          setProfilePreviewSelected={setProfilePreviewSelected}
          shareProfileOnPress={navigateShareProfile}
          tabValue={tabValue}
        />
        <ListsPanel tabValue={tabValue} />
        <UploadDataPanel
          setProfilePreviewSelected={setProfilePreviewSelected}
          importOnPress={navigateNewUpload}
          tabValue={tabValue}
        />
        <IntegrationsPanel tabValue={tabValue} />
      </div>
      {shareProfileOpen ? (
        <ShareProfilePopupPanel
          isVisible={shareProfileOpen}
          setIsVisible={setShareProfileOpen}
        />
      ) : null}
      {profilePreviewSelected ? (
        <ProfilePreviewModal
          userData={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
          messageOnPress={(item) => setConversationUser(item)}
          hideProfilePreview={hideProfilePreview}
          setHideProfilePreview={setHideProfilePreview}
          fullProfileOpen={fullProfileOpen}
          setFullProfileOpen={setFullProfileOpen}
        />
      ) : null}
      {conversationUser ? (
        <ConversationsSidebarModal
          conversationUser={conversationUser}
          setConversationUser={setConversationUser}
          reopenProfilePreview={() =>
            hideProfilePreview && !fullProfileOpen
              ? setHideProfilePreview(false)
              : null
          }
          fullProfileOpen={fullProfileOpen}
        />
      ) : null}
      {subModalVisible ? (
        <MarkitPlusModal closeModal={() => setSubModalVisible(false)} />
      ) : null}
      <ImportantAnnouncementModal />
    </div>
  );
};

export default HomePanel;
