import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import {
  LessThanDate,
  checkTwilioMessagingSurgeHit,
  deepCopy,
  detectedCensored,
  getEventAttachmentLinks,
  hostingLiveEvents,
  massTextMessageAttachmentsValidation,
  uniqueVals,
  detectedLinkShortener,
  calculateTextMessageCreditsEstimate,
} from "@markit/common.utils";
import {
  FREE_TIER_MESSAGE_LIMIT,
  MassText,
  SubSelectionItem,
} from "@markit/common.types";
import { API } from "../../../API";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { MassTextMessagingScreenType } from "./MassTextsPopupPanel";
import mixpanel from "mixpanel-browser";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import HelpModal from "../../Containers/HelpModal";
import TestTextModal from "./TestTextModal";
import { getEventState } from "../../../redux/slices/eventSlice";
import { ScheduleTextInfo } from "./MassTextsMessaging";
import AlertContainer from "../../Containers/AlertContainer";
import ConfirmActionModal from "../../Containers/ConfirmPopups/ConfirmActionModal";
import { CircularProgress } from "@mui/material";
import { useTheme } from "../../../hooks/useTheme";
import { saveMediaToFirestore } from "../../../utils/photoUtils";
import ContentViolationAlert from "../../DeliveryScore/ContentViolationAlert";
import { TextPopupConfirmActions } from "../../../screens/Profile/CreatorPanels/TextingPanel";

type MassTextsMessagingHeaderProps = {
  screen: MassTextMessagingScreenType;
  setScreen: (screen: MassTextMessagingScreenType) => void;
  massText: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  resetState: () => void;
  madeChanges: boolean;
  finalRecipients: string[];
  suggestedAction: SubSelectionItem | undefined;
  scheduleTextInfo: ScheduleTextInfo;
  setScheduleTextInfo: (ScheduleTextInfo: ScheduleTextInfo) => void;
  isEditing: boolean;
  totalDailyMessages: number;
  loadingDailyMessages: boolean;
  loadingCategoryRecipients: boolean;
  // for text send/schedule success/fail/delete popups
  setConfirmPopupTextConfirmation?: (
    confirmType: TextPopupConfirmActions
  ) => void;
};

const MassTextsMessagingHeader = (props: MassTextsMessagingHeaderProps) => {
  const {
    screen,
    setScreen,
    massText,
    updateMassTextSettings,
    resetState,
    madeChanges,
    finalRecipients,
    suggestedAction,
    scheduleTextInfo,
    setScheduleTextInfo,
    isEditing,
    totalDailyMessages,
    loadingDailyMessages,
    loadingCategoryRecipients,
    setConfirmPopupTextConfirmation,
  } = props;
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { events } = useSelector(getEventState).events;
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [showConfirmBackModal, setShowConfirmBackModal] =
    useState<boolean>(false);
  const [showDeleteMassTextModal, setShowDeleteMassTextModal] =
    useState<boolean>(false);
  const [showTestTextModal, setShowTestTextModal] = useState(false);

  const [initialMassText, setInitialMassText] = useState(deepCopy(massText));
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [messageContentAlert, setMessageContentAlert] = useState(false);
  const [sendTextConfirm, setSendTextConfirm] = useState(false);
  const [sending, setSending] = useState(false);

  const styles = {
    headerTitle: { fontSize: 14, fontWeight: "500" },
    headerPosition: {
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      zIndex: -1,
    },
  };

  // The event that the event text is for
  const foundEventText = useMemo(
    () => events.find((event) => event.id === massText.eventRefId),
    [events, massText.eventRefId]
  );

  const numTextCredits = useMemo(
    () =>
      calculateTextMessageCreditsEstimate(
        massText.message,
        massText.mediaUrl,
        foundEventText
      ),
    [foundEventText, massText.mediaUrl, massText.message]
  );

  const isSendTimePassed = useMemo(
    () => LessThanDate(massText.sentAt, new Date().toISOString()),
    [massText.sentAt]
  );

  const noBackAlert = useMemo(
    () => screen === MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY,
    [screen]
  );

  const handleBackNavigation = useCallback(() => {
    switch (screen) {
      case MassTextMessagingScreenType.LINK_ANALYTICS:
        setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
        break;
      case MassTextMessagingScreenType.SUMMARY:
      case MassTextMessagingScreenType.SEND_SUMMARY:
        resetState();
        break;
      case MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY:
        setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
        break;
      case MassTextMessagingScreenType.SEND_SCHEDULE:
        setScheduleTextInfo({
          scheduleToggle: massText.scheduled,
          sendTime: new Date(massText.sentAt),
          catchUpChecked: massText.catchUpEnabled,
        });
        setScreen(
          isEditing
            ? MassTextMessagingScreenType.SEND_SUMMARY
            : MassTextMessagingScreenType.SUMMARY
        );
        break;
    }
  }, [
    isEditing,
    massText.catchUpEnabled,
    massText.scheduled,
    massText.sentAt,
    resetState,
    screen,
    setScheduleTextInfo,
    setScreen,
  ]);

  const backOnPress = useCallback(() => {
    if (!madeChanges || noBackAlert) {
      handleBackNavigation();
    } else {
      setShowConfirmBackModal(true);
    }
  }, [madeChanges, noBackAlert, handleBackNavigation]);

  const showTopRightButton = useCallback(() => {
    return (
      screen !== MassTextMessagingScreenType.SEND_SUMMARY &&
      screen !== MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY &&
      screen !== MassTextMessagingScreenType.LINK_ANALYTICS
    );
  }, [screen]);

  const deleteScheduledMassText = useCallback(async () => {
    setShowDeleteMassTextModal(false);
    if (massText) {
      await API.text
        .deleteMassTextMessage({
          userId: accountData.uid,
          massTextId: massText.id,
        })
        .then((response) => {
          if (response.success) {
            if (!response.success) {
              setAlertText({
                heading: "Failed to delete!",
                subHeading: "Please try again.",
              });
            } else {
              if (setConfirmPopupTextConfirmation) {
                setConfirmPopupTextConfirmation(
                  TextPopupConfirmActions.TEXT_DELETE
                );
              }
              resetState();
            }
          }
        });
    }
  }, [massText, accountData.uid, setConfirmPopupTextConfirmation, resetState]);

  const eventsHostingCanMassText = useMemo(
    () => hostingLiveEvents(events, accountData.uid, true).reverse(),
    [accountData.uid, events]
  );

  const eventAttachmentLinks = useMemo(() => {
    const allEventIds = uniqueVals(
      massText.eventRefId
        ? eventsHostingCanMassText
            .map((event) => event.id)
            .concat(massText.eventRefId)
        : eventsHostingCanMassText.map((event) => event.id)
    );
    return getEventAttachmentLinks(massText.message, allEventIds);
  }, [eventsHostingCanMassText, massText.eventRefId, massText.message]);
  const openSentTextPopup = useCallback(
    (isSuccessful: boolean) => {
      if (setConfirmPopupTextConfirmation) {
        setConfirmPopupTextConfirmation(
          isSuccessful
            ? TextPopupConfirmActions.SENT_SUCCESS
            : TextPopupConfirmActions.SENT_FAILURE
        );
      }
    },
    [setConfirmPopupTextConfirmation]
  );

  const openScheduledTextPopup = useCallback(
    (isSuccessful: boolean) => {
      if (setConfirmPopupTextConfirmation) {
        setConfirmPopupTextConfirmation(
          isSuccessful
            ? TextPopupConfirmActions.SCHEDULED_SUCCESS
            : TextPopupConfirmActions.SCHEDULED_FAILURE
        );
      }
    },
    [setConfirmPopupTextConfirmation]
  );

  const sendMassText = useCallback(async () => {
    const finalMessage = massTextMessageAttachmentsValidation(
      massText.message,
      eventAttachmentLinks,
      finalRecipients.length
    );

    setSending(true);

    // upload media if it exists
    let mediaUrl = "";
    if (massText.mediaUrl !== "") {
      const url = await saveMediaToFirestore(
        massText.mediaUrl,
        accountData.uid + "/textMedia/massTexts/" + massText.id
      );
      mediaUrl = url;
    }

    const finalSelectedSubSelectionIds = suggestedAction
      ? [suggestedAction]
      : massText.subSelectionIds || [];
    await API.text
      .sendCustomMessage({
        userData: accountData,
        message: finalMessage,
        sendTime: massText.scheduled ? massText.sentAt : "",
        catchUpEnabled: massText.catchUpEnabled,
        eventRefId: massText.eventRefId,
        subSelectionIds: finalSelectedSubSelectionIds,
        excludedSubSelectionIds: massText.excludedSubSelectionIds,
        recipients: finalRecipients,
        numExcludedRecipients: 0,
        mediaUrl: mediaUrl,
        massTextId: massText.id,
      })
      .then((response) => {
        if (!response.success) {
          setAlertText({
            heading: "An error occurred.",
            subHeading:
              "Please contact our hotline 617-213-0897 to diagnose the issue before attempting to send again.",
          });

          if (massText.scheduled) {
            openScheduledTextPopup(false);
          } else {
            openSentTextPopup(false);
          }

          mixpanel.track("Mass Text Message Failed", {
            message: finalMessage,
            recipientsLength: finalRecipients.length,
            numExcludedRecipients: 0,
            errorMessage: "response.success returned false",
          });
          return;
        }
        if (mixpanel) {
          mixpanel.track("Mass Text Message Initialized", {
            source: "webapp",
            type: response.massText.type,
            massTextId: response.massText.id,
          });
        }
        updateMassTextSettings({
          id: response.massText.id,
          sentAt: response.massText.sentAt,
        });
        if (massText.scheduled) {
          openScheduledTextPopup(response.success);
        } else {
          openSentTextPopup(response.success);
        }
      })
      .catch((e: any) => {
        setAlertText({
          heading: "An error occurred.",
          subHeading:
            "Please contact our hotline 617-213-0897 to diagnose the issue before attempting to send again.",
        });

        mixpanel.track("Mass Text Message Failed", {
          message: massText.message,
          recipientsLength: finalRecipients.length,
          numExcludedRecipients: 0,
          errorMessage: e.message,
        });
      });
    // handle correct navigation to after sending
    if (massText.scheduled) {
      resetState();
    } else {
      setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
    }
  }, [
    massText.message,
    massText.mediaUrl,
    massText.subSelectionIds,
    massText.scheduled,
    massText.sentAt,
    massText.catchUpEnabled,
    massText.eventRefId,
    massText.excludedSubSelectionIds,
    massText.id,
    eventAttachmentLinks,
    finalRecipients,
    suggestedAction,
    accountData,
    updateMassTextSettings,
    openScheduledTextPopup,
    resetState,
    openSentTextPopup,
    setScreen,
  ]);

  const confirmMassText = useCallback(async () => {
    // if message is not set
    if (massText.message === "") {
      setAlertText({
        heading: "Write your message first!",
        subHeading: "Select recipients and write your message before sending.",
      });
      return;
    }
    // if message length exceeds max count
    if (massText.message.length > 1500) {
      setAlertText({
        heading: "Exceeded max character count of 1500.",
        subHeading: "Shorten your message before trying to send again.",
      });
      return;
    }
    // if banned terms exist in message
    if (detectedCensored(massText.message)) {
      setMessageContentAlert(true);
      return;
    }
    if (detectedLinkShortener(massText.message)) {
      setAlertText({
        heading: "Your message includes a known url link shortener.",
        subHeading: "Remove them before trying to send again.",
      });
      return;
    }
    // loading state for fetching the recipients from the categories
    if (loadingCategoryRecipients) {
      setAlertText({
        heading: "Not ready to send.",
        subHeading:
          "Currently fetching the recipients this text will be sent to. This will only take a few seconds.",
      });
      return;
    }
    // if an event text and not scheduled and there are no attendees for the event
    if (
      finalRecipients.length === 0 &&
      massText.eventRefId &&
      !massText.scheduled
    ) {
      setAlertText({
        heading: "This message cannot be sent immediately.",
        subHeading:
          "You have no attendees within the custom selection for this event. You can schedule this text or send to individual recipients instead.",
      });
      return;
    }
    // if there are no recipients selected for a non-category text
    if (finalRecipients.length === 0 && !massText.eventRefId) {
      setAlertText({
        heading: "Select recipients first!",
        subHeading: "Select recipients and write your message before sending.",
      });
      return;
    }
    if (
      accountData.customer.phone === "" &&
      accountData.customer.freeTierMessages >= FREE_TIER_MESSAGE_LIMIT
    ) {
      setAlertText({
        heading: "You are at the maximum free texts for this month.",
        subHeading:
          "You can get started on Markit+ for as little as $10 to receive more texts, or wait until the start of next month for your free message count to reset.",
      });
      return;
    }
    if (
      accountData.customer.phone === "" &&
      accountData.customer.freeTierMessages +
        finalRecipients.length * numTextCredits >=
        FREE_TIER_MESSAGE_LIMIT
    ) {
      setAlertText({
        heading:
          "This text exceeds the number of free texts you can send this month",
        subHeading:
          "You can get started on Markit+ for as little as $10 to receive more texts, or reduce the number of recipients to fit within your free message count limit.",
      });
      return;
    }
    if (massText.scheduled && isSendTimePassed) {
      setAlertText({
        heading: "The scheduled send time is set before the current time.",
        subHeading: "Please edit the send time before sending.",
      });
      return;
    }
    // if the daily message count is loading, there are more than 1000 recipients selected, and the user is not a subaccount
    if (
      loadingDailyMessages &&
      finalRecipients.length > 100 &&
      !accountData.customer.subAccountSid
    ) {
      setAlertText({
        heading: "Not ready to send.",
        subHeading:
          "We are making sure everything is all set. This will only take up to a minute.",
      });
      return;
    }
    // If there are more than 100 recipients selected, the messaging surge is hit, and the user is not a subaccount
    if (
      finalRecipients.length > 100 &&
      checkTwilioMessagingSurgeHit(
        totalDailyMessages + finalRecipients.length,
        accountData.customer.subAccountSid
      )
    ) {
      mixpanel.track("Mass Texting Surge Modal Visible", {
        totalTwilioMessages: totalDailyMessages,
        numRecipients: finalRecipients.length,
        text_type: "mass text",
        from: "web-app",
      });
      setAlertText({
        heading: "We are currently experiencing a surge in Mass Texting.",
        subHeading:
          "To ensure critical texts are being sent [tickets, confirmations, etc], we have locked this feature until 12AM PST. Scheduled texts and Event texts will still be sent. Thank you for your patience.",
      });
      return;
    }
    setSendTextConfirm(true);
  }, [
    massText.message,
    massText.eventRefId,
    massText.scheduled,
    loadingCategoryRecipients,
    finalRecipients.length,
    accountData.customer.phone,
    accountData.customer.freeTierMessages,
    accountData.customer.subAccountSid,
    numTextCredits,
    isSendTimePassed,
    loadingDailyMessages,
    totalDailyMessages,
  ]);

  const handleTopRightButton = useCallback(async () => {
    if (screen === MassTextMessagingScreenType.SUMMARY) {
      confirmMassText();
    } else if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
      updateMassTextSettings({
        scheduled: scheduleTextInfo.scheduleToggle,
        sentAt: scheduleTextInfo.sendTime.toISOString(),
        catchUpEnabled: scheduleTextInfo.catchUpChecked,
      });
      setScreen(
        isEditing
          ? MassTextMessagingScreenType.SEND_SUMMARY
          : MassTextMessagingScreenType.SUMMARY
      );
    }
  }, [
    confirmMassText,
    isEditing,
    scheduleTextInfo.catchUpChecked,
    scheduleTextInfo.scheduleToggle,
    scheduleTextInfo.sendTime,
    screen,
    setScreen,
    updateMassTextSettings,
  ]);

  const saveChangesToScheduledMassText = useCallback(async () => {
    if (massText) {
      setSending(true);

      let finalUpdatedMassText = massText;
      if (
        massText.mediaUrl !== "" &&
        massText.mediaUrl !== initialMassText.mediaUrl
      ) {
        const url = await saveMediaToFirestore(
          massText.mediaUrl,
          accountData.uid + "/textMedia/massTexts/" + massText.id
        );
        finalUpdatedMassText = {
          ...massText,
          mediaUrl: url,
        };
      }

      await API.text
        .updateMassTextMessage({
          userId: accountData.uid,
          massText: finalUpdatedMassText,
        })
        .then((response) => {
          if (!response.success) {
            setAlertText({
              heading: "Failed to update!",
              subHeading: "Please try again.",
            });
          } else {
            resetState();
          }
        });
      setSending(false);
    }
  }, [massText, initialMassText.mediaUrl, accountData.uid, resetState]);

  const renderTestButton = useMemo(
    () => (
      <div
        className="MassTextsSummaryEditButton"
        style={{ backgroundColor: Colors.GRAY6 }}
        onClick={() => {
          if (detectedCensored(massText.message)) {
            setAlertText({
              heading: "Please remove censored terms before testing.",
              subHeading: "",
            });
            return;
          }
          setShowTestTextModal(true);
        }}
      >
        <span style={styles.headerTitle}>Test</span>
      </div>
    ),
    [massText.message, styles.headerTitle]
  );

  return (
    <>
      <div
        className="AlignedRowSpaced"
        style={{ position: "relative", paddingTop: 14 }}
      >
        <div className="LargePopupPanelClose" onClick={backOnPress}>
          <Icon
            icon={
              screen === MassTextMessagingScreenType.SUMMARY ||
              screen === MassTextMessagingScreenType.SEND_SUMMARY
                ? "mdi:close"
                : "ion:chevron-back"
            }
            height={24}
            color={Colors.GRAY3}
          />
        </div>
        {screen === MassTextMessagingScreenType.SEND_SCHEDULE ? (
          <div style={{ ...styles.headerPosition, position: "absolute" }}>
            <span style={styles.headerTitle}>Sending Schedule</span>
          </div>
        ) : screen === MassTextMessagingScreenType.SEND_SUMMARY &&
          isSendTimePassed ? (
          <div style={{ ...styles.headerPosition, position: "absolute" }}>
            <p style={styles.headerTitle}>Text Summary</p>
          </div>
        ) : null}
        <div className="AlignedRow" style={{ gap: 7 }}>
          {screen === MassTextMessagingScreenType.SUMMARY
            ? renderTestButton
            : null}
          <div
            className="LargePopupPanelBlackButton AlignedRowSelect"
            onClick={handleTopRightButton}
            style={{
              visibility: showTopRightButton() ? undefined : "hidden",
              pointerEvents:
                screen === MassTextMessagingScreenType.SEND_SCHEDULE &&
                !madeChanges
                  ? "none"
                  : "all",
              opacity:
                screen === MassTextMessagingScreenType.SEND_SCHEDULE &&
                !madeChanges
                  ? 0.2
                  : 1,
              gap: 5,
            }}
          >
            {sending ? (
              <CircularProgress
                style={{
                  ...(theme ? theme.ButtonText : { color: Colors.WHITE }),
                  alignSelf: "center",
                }}
                size={17}
              />
            ) : (
              <>
                <span style={{ ...styles.headerTitle, color: Colors.WHITE }}>
                  {screen === MassTextMessagingScreenType.SUMMARY
                    ? massText.scheduled
                      ? "Schedule"
                      : "Send"
                    : "Done"}
                </span>
                {screen === MassTextMessagingScreenType.SUMMARY ? (
                  <Icon
                    icon={massText.scheduled ? "ion:timer" : "ion:paper-plane"}
                    height={18}
                    color={Colors.WHITE}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
        {/* Mass Texts Send Summary */}
        {screen === MassTextMessagingScreenType.SEND_SUMMARY ? (
          isSendTimePassed ? (
            <div
              className="LargePopupPanelGrayButton"
              onClick={() => {
                setShowHelpModal(true);
              }}
            >
              <span style={styles.headerTitle}>Help</span>
            </div>
          ) : (
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon
                onClick={() => setShowDeleteMassTextModal(true)}
                icon="ion:trash-outline"
                height={24}
                color={Colors.RED1}
                style={{ cursor: "pointer" }}
              />
              {renderTestButton}
              {isEditing ? (
                <div
                  className={`${
                    madeChanges
                      ? "LargePopupPanelBlackButton"
                      : "LargePopupPanelGrayButton"
                  }`}
                  onClick={saveChangesToScheduledMassText}
                  style={{
                    pointerEvents: madeChanges ? "all" : "none",
                    cursor: "pointer",
                  }}
                >
                  {sending ? (
                    <CircularProgress
                      style={{
                        ...(theme ? theme.ButtonText : { color: Colors.WHITE }),
                        alignSelf: "center",
                      }}
                      size={17}
                    />
                  ) : (
                    <span
                      style={{
                        color: madeChanges ? Colors.WHITE : Colors.GRAY2,
                        ...styles.headerTitle,
                      }}
                    >
                      Save
                    </span>
                  )}
                </div>
              ) : null}
            </div>
          )
        ) : null}
      </div>
      <hr className="MassTextsHr" />
      <ConfirmDeleteModal
        heading="Are you sure you want to delete this scheduled text?"
        subtext="You cannot undo this."
        deleteButtonText="Delete"
        hideModal={!showDeleteMassTextModal}
        setIsVisible={setShowDeleteMassTextModal}
        deleteOnPress={deleteScheduledMassText}
      />
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your edits won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!showConfirmBackModal}
        setIsVisible={setShowConfirmBackModal}
        deleteOnPress={() => {
          setShowConfirmBackModal(false);
          if (screen === MassTextMessagingScreenType.SUMMARY) {
            resetState();
          } else {
            handleBackNavigation();
          }
        }}
        icon={<Icon icon="ion:exit-outline" height={40} />}
      />
      <TestTextModal
        isVisible={showTestTextModal}
        setIsVisible={setShowTestTextModal}
        message={massText.message}
        image={massText.mediaUrl}
        save={massText.mediaUrl !== initialMassText.mediaUrl}
        event={foundEventText}
      />
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ContentViolationAlert
        message={massText.message}
        isVisible={messageContentAlert}
        setIsVisible={setMessageContentAlert}
      />
      <ConfirmActionModal
        heading={`Ready to ${massText.scheduled ? "schedule" : "send"}?`}
        subtext={`${
          !massText.scheduled
            ? `This ${numTextCredits} credit text will send to ${
                finalRecipients.length
              } recipient${
                finalRecipients.length !== 1 ? "s" : ""
              }. This cannot be undone.`
            : ""
        }`}
        confirmButtonText={massText.scheduled ? "Schedule" : "Send Text"}
        icon={
          massText.scheduled ? (
            <Icon icon="ion:timer" height={40} />
          ) : (
            <Icon icon="ion:paper-plane" height={40} />
          )
        }
        hideModal={!sendTextConfirm}
        setIsVisible={setSendTextConfirm}
        confirmOnPress={sendMassText}
      />
    </>
  );
};

export default MassTextsMessagingHeader;
