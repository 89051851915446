import { memo, useCallback, useContext, useMemo, useState } from "react";
import { AccountData, Event, VerificationState } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import { MixpanelContext } from "../../context/AnalyticsService";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  getAccountState,
  logoutUser,
} from "../../redux/slices/accountSlice";
import VerificationCodeButton from "../Verification/VerificationCodeButton";
import { CheckoutEmailAccountNotice } from "../FullEventSubComponents/CheckoutEmailAccountNotice";
import CustomTextField from "../../components/CustomTextField";
import {
  MARKIT_PRIVACY_POLICY,
  PhoneErrorType,
  detectedCensored,
  isEventExternalLink,
} from "@markit/common.utils";
import ProfilePic from "../ProfilePic";
import MarkitSecuredContainer from "./MarkitSecuredContainer";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { useNavigate } from "../../hooks/useNavigate";
import CustomPhoneTextField from "../TextFields/CustomPhoneTextField";
import { isPhoneNumberValid } from "../../utils/FormatPhoneNumber";
import { Icon } from "@iconify/react";
import { showPhoneInputError } from "../../utils/phoneUtils";
import { GLOWBOSTON_UID } from "../../utils/facebookPixelEvents";

type NameAndPhoneNumberInputProps = {
  host: AccountData;
  verificationState: VerificationState;
  setVerificationState: (verificationState: VerificationState) => void;
  optedOut: boolean;
  setOptedOut: (optedOut: boolean) => void;
  optedOutCreator: boolean;
  setOptedOutCreator: (optedOutCreator: boolean) => void;
  tempFullName: string;
  setTempFullName: (tempFullName: string) => void;
  tempPhoneNumber: string;
  setTempPhoneNumber: (tempPhoneNumber: string) => void;
  setUid: (uid: string) => void;
  processing: boolean;
  setIsOrganizer?: (isOrganizer: boolean) => void;
  event?: Event;
  callbackOnVerify?: () => void;
};

export const NameAndPhoneNumberInput = memo(function NameAndPhoneNumberInputFn(
  props: NameAndPhoneNumberInputProps
) {
  const {
    host,
    verificationState,
    setVerificationState,
    optedOut,
    setOptedOut,
    optedOutCreator,
    setOptedOutCreator,
    tempFullName,
    setTempFullName,
    tempPhoneNumber,
    setTempPhoneNumber,
    setUid,
    setIsOrganizer,
    processing,
    event,
    callbackOnVerify,
  } = props;
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accountData, loggedIn } = useSelector(getAccountState).account;

  const [showFullNameError, setShowFullNameError] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] =
    useState<PhoneErrorType>({ isError: false, message: "" });
  const [showCodeInputError, setShowCodeInputError] = useState(false);

  const styles = {
    optInText: { fontSize: 10, color: Colors.GRAY2 },
  };

  const isEmailBasedAccount = useMemo(
    () => accountData.email !== "",
    [accountData.email]
  );

  const logoutEmailOnPress = useCallback(() => {
    mixpanel.track("Webapp: User Logout", {
      distinct_id: accountData.uid,
    });
    dispatch(logoutUser());
    setUid("");
    if (setIsOrganizer) {
      setIsOrganizer(false);
    }
    setVerificationState(VerificationState.UNVERIFIED);
  }, [
    accountData.uid,
    dispatch,
    mixpanel,
    setIsOrganizer,
    setUid,
    setVerificationState,
  ]);

  const showError = useCallback(() => {
    let error = false;

    if (showPhoneInputError(tempPhoneNumber, setShowPhoneNumberError)) {
      error = true;
    }

    if (tempFullName === "" || detectedCensored(tempFullName)) {
      if (detectedCensored(tempFullName)) {
        alert("Full name contains banned words and must be removed.");
      }
      setShowFullNameError(true);
      error = true;
    }

    // todo (jonathan): not sure this is needed. Seems like all it does is turn code input red when you resend code
    if (verificationState === VerificationState.VERIFYING) {
      setShowCodeInputError(true);
    }

    return error;
  }, [tempFullName, tempPhoneNumber, verificationState]);

  const navigateProfileOnPress = useCallback(() => {
    dispatch(DataLoaders.user(host.uid));
    navigate(`/u/${host.username}`, {
      state: { username: host.username },
    });
  }, [host.uid, host.username, dispatch, navigate]);

  return (
    <div
      className={isDesktop ? "YourInfoContainerDesktop" : "YourInfoContainer"}
      style={theme.SecondaryBG}
    >
      {event && isEventExternalLink(event.eventType) ? (
        <div
          className="AlignedRowSpaced"
          style={{ gap: 14, paddingBottom: 14, paddingInline: 14 }}
        >
          <div>
            <ProfilePic
              user={host}
              size={49}
              onPress={navigateProfileOnPress}
            />
          </div>
          <span
            style={{ fontWeight: "500", fontSize: 14, ...theme.PrimaryText }}
          >
            {host.fullName} uses Markit to send updates and reminders
          </span>
        </div>
      ) : (
        <div style={{ paddingBottom: 14 }}>
          <span
            style={{
              ...theme.PrimaryText,
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Your Info
          </span>
          {verificationState !== VerificationState.VERIFIED ? (
            <div>
              <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                Just for updates. We hide your number.
              </span>
            </div>
          ) : null}
        </div>
      )}
      <div className="ColumnNormal" style={{ gap: "14px" }}>
        <CustomTextField
          value={tempFullName}
          placeholder="Your name"
          disabled={
            processing || verificationState === VerificationState.VERIFIED
          }
          inputMode="text"
          borderRadius={12}
          onChange={(name: any) => {
            setShowFullNameError(false);
            setTempFullName(name.target.value);
          }}
          error={showFullNameError}
        />
        {isEmailBasedAccount ? (
          <CheckoutEmailAccountNotice
            email={accountData.email}
            logoutEmailOnPress={logoutEmailOnPress}
          />
        ) : (
          <div>
            <CustomPhoneTextField
              placeholder="Phone number"
              phoneNumber={tempPhoneNumber}
              onPhoneChange={(value) => {
                setShowPhoneNumberError({ isError: false, message: "" });
                setTempPhoneNumber(value);
              }}
              showPhoneError={showPhoneNumberError}
              disabled={
                processing ||
                verificationState === VerificationState.VERIFIED ||
                verificationState === VerificationState.VERIFYING
              }
              endAdornment={
                verificationState === VerificationState.VERIFIED ? (
                  <div
                    className="AlignedRow"
                    style={{ gap: "5px", color: Colors.GREEN2 }}
                  >
                    <span className="bodyMedium">Verified</span>
                    <Icon
                      icon="ion:checkmark-circle"
                      height={20}
                      style={{ color: Colors.GREEN2 }}
                    />
                  </div>
                ) : verificationState === VerificationState.UNVERIFIED ? (
                  showPhoneNumberError.isError ||
                  (tempPhoneNumber && isPhoneNumberValid(tempPhoneNumber)) ? (
                    <Icon
                      icon={
                        showPhoneNumberError.isError
                          ? "ion:close-circle-outline"
                          : "ion:checkmark-circle-outline"
                      }
                      height={20}
                      style={{
                        marginBottom: -2,
                        color: showPhoneNumberError.isError
                          ? Colors.RED2
                          : Colors.GREEN2,
                      }}
                    />
                  ) : undefined
                ) : undefined
              }
            />
            {verificationState === VerificationState.UNVERIFIED ? (
              <div style={{ marginTop: 7 }}>
                <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                  We'll send a verification code to this number.
                </span>
              </div>
            ) : null}
          </div>
        )}
        <VerificationCodeButton
          event={event}
          signIn={false}
          buttonText="Verify Number"
          tempFullName={tempFullName}
          tempPhoneNumber={tempPhoneNumber}
          setNewUid={setUid}
          verificationState={verificationState}
          setVerificationState={setVerificationState}
          optedOut={optedOut}
          setOptedOut={setOptedOut}
          optedOutCreator={optedOutCreator}
          setOptedOutCreator={setOptedOutCreator}
          showCodeInputError={showCodeInputError}
          setShowCodeInputError={setShowCodeInputError}
          showError={showError}
          host={host}
          callbackOnVerify={callbackOnVerify}
          processing={processing}
        />
        {/** For when an acount is being converted to subaccount and phone number gets moved */}
        {/* {event?.createdBy === "qB9dhzxgPYf62LHiMQh7yhSifaX2" ||
        host.fullName === "Glamhospitality" ? (
          <p
            style={{
              fontSize: 12,
              fontWeight: "500",
              textAlign: "center",
              marginInline: 20,
              color: "#929292",
            }}
          >
            <span
              style={{ color: Colors.RED1, fontWeight: "600", fontSize: 14 }}
            >
              Attention!
            </span>{" "}
            There are some maintenance changes currently being done. Please
            check back in half an hour. Thank you.
          </p>
        ) : null} */}
        {!isEmailBasedAccount &&
        loggedIn === LoginState.LOGGED_IN &&
        event &&
        event.createdBy === GLOWBOSTON_UID ? (
          <p style={styles.optInText}>
            By continuing, you consent to Markit's use of the Meta Pixel to
            track user interactions and enhance our advertising efforts. For
            more information on how we use this technology and your data, please
            read our{" "}
            <span
              style={{ ...theme.LinkColor, cursor: "pointer" }}
              onClick={() => window.open(MARKIT_PRIVACY_POLICY)}
            >
              Privacy Policy
            </span>
            .
          </p>
        ) : null}
        {!isEmailBasedAccount &&
        event &&
        isEventExternalLink(event.eventType) ? (
          <MarkitSecuredContainer />
        ) : null}
      </div>
    </div>
  );
});
