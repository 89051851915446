import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  Follower,
  SpreadsheetStatus,
  FollowerStatus,
} from "@markit/common.types";
import {
  getUserFollowerData,
  getUserFollowersRef,
} from "../utils/FirebaseUtils";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";

type useLoadSpreadsheetUserListProps = {
  userId: string;
  followerStatus: FollowerStatus;
  windowSize: number;
  status: SpreadsheetStatus;
  fileId?: string;
};
export const useLoadSpreadsheetUserList = (
  props: useLoadSpreadsheetUserListProps
) => {
  const { userId, followerStatus, windowSize, status, fileId } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const addUserData = useCallback(
    (newUserData: AccountData[]) => {
      dispatch(accountActions.addMultipleFollowerAccountData(newUserData));
      setFetchedUserData((fetchedUserData) =>
        uniqueVals(
          fetchedUserData.concat(newUserData),
          (userData) => userData.uid
        )
      );
    },
    [dispatch]
  );

  const removeUserData = useCallback((newUserDataUid: string) => {
    setFetchedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === newUserDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return uniqueVals(
        fetchedUserData,
        (fetchedUserData) => fetchedUserData.uid
      );
    });
  }, []);

  const addFollowerData = useCallback((newFollower: Follower) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollower.uid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1, newFollower);
      } else {
        fetchedFollowerData = fetchedFollowerData.concat(newFollower);
      }
      return uniqueVals(
        fetchedFollowerData,
        (fetchedFollowerData) => fetchedFollowerData.uid
      );
    });
  }, []);

  const removeFollowerData = useCallback((newFollowerUid: string) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollowerUid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1);
      }
      return uniqueVals(
        fetchedFollowerData,
        (fetchedFollowerData) => fetchedFollowerData.uid
      );
    });
  }, []);

  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        const followerData = await fetchSingleFollower(
          userId,
          userData.uid,
          followersData
        );
        if (followerData && followerData.status === followerStatus) {
          dispatch(accountActions.addToFollowerData(followerData));
          addFollowerData(followerData);
          addUserData([userData]);
        }
      }
    },
    [
      addFollowerData,
      addUserData,
      dispatch,
      followerStatus,
      followersData,
      followingAccountData,
      userId,
    ]
  );

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef = getUserFollowersRef(userId);
      const followMembersQuery = fileId
        ? query(
            followMembersRef,
            where("spreadsheetData.fileId", "==", fileId),
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            limit(windowSize)
          )
        : query(
            followMembersRef,
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            limit(windowSize)
          );

      let uploadedMembers: string[] = [];
      try {
        uploadedMembers = (await getDocs(followMembersQuery)).docs.map(
          (document) => document.data().createdAt
        );
      } catch (e: any) {
        console.error(e.message);
      }

      if (uploadedMembers.length) {
        setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      }

      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberUserData = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            addFollowerData(memberUserData as Follower);
            fetchSingleUser(
              memberUserData.uid,
              followingAccountData,
              (userData) => addUserData([userData])
            );
          } else if (message.type === "removed") {
            removeFollowerData(memberUserData.uid);
            removeUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return unsubscribe;
    }
  }, [
    addFollowerData,
    addUserData,
    fileId,
    followingAccountData,
    followerStatus,
    isFinished,
    isLoading,
    removeFollowerData,
    removeUserData,
    status,
    userId,
    windowSize,
  ]);

  const loadMoreUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef = getUserFollowersRef(userId);
      const followMembersQuery = fileId
        ? query(
            followMembersRef,
            where("spreadsheetData.fileId", "==", fileId),
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          )
        : query(
            followMembersRef,
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          );

      let uploadedMembers: string[] = [];
      try {
        uploadedMembers = (await getDocs(followMembersQuery)).docs.map(
          (document) => document.data().createdAt
        );
      } catch (e: any) {
        console.error(e.message);
      }

      if (uploadedMembers.length) {
        setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      }
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberUserData = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            addFollowerData(memberUserData as Follower);
            fetchSingleUser(
              memberUserData.uid,
              followingAccountData,
              (userData) => addUserData([userData])
            );
          } else if (message.type === "removed") {
            removeFollowerData(memberUserData.uid);
            removeUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return unsubscribe;
    }
  }, [
    addFollowerData,
    addUserData,
    fileId,
    followerStatus,
    followingAccountData,
    isFinished,
    isLoading,
    lastVisible,
    removeFollowerData,
    removeUserData,
    status,
    userId,
    windowSize,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchFollowers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            await checkIfAddUserData(uid);
          })
        );
        setIsLoading(false);
      };
      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchFollowers(userIds)
      );
    },
    [userId, checkIfAddUserData]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedFollowerData,
    loadUsers,
    loadMoreUsers,
    loadSearchResultsThrottled,
  };
};
