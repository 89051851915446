import { useCallback, useMemo, useState } from "react";
import { AccountData, FollowerStatus } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import GridTableHeader from "../Headers/GridTableHeader";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import { useOnMount } from "../../utils/useOnMount";
import { useLoadUserFollowList } from "../../hooks/useLoadUserFollowList";
import filter from "lodash.filter";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import StandardListContainer from "../Containers/StandardListContainer";
import { exportAllFollowerData } from "../../utils/eventUtils/formUtils";
import { TabPanel } from "../FullEventSubComponents/TabPanel";
import ProfileFollowerItem, {
  FOLLOWER_USER_STATUS_FLEX_SECTIONS,
} from "../DisplayItem/ProfileItem/ProfileFollowerItem";
import ProgressActionModal, {
  ProgressActionStatus,
} from "../Containers/ProgressActionModal";
import { EmptyStateFlatlist } from "../EmptyStates/EmptyStateFlatlist";
import ListTabs from "../DropdownsAndTabs/ListTabs";
import { useLiveUpdatingAudienceAnalytics } from "../../hooks/useLiveHooks/useLiveUpdatingAudienceAnalytics";

type AudiencePanelProps = {
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  shareProfileOnPress: () => void;
  tabValue: number;
};

export const AudiencePanel = (props: AudiencePanelProps) => {
  const { setProfilePreviewSelected, shareProfileOnPress, tabValue } = props;
  const { accountData, appInitialized } = useSelector(getAccountState).account;
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [listValue, setListValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmPopupExport, setConfirmPopupExport] =
    useState<ProgressActionStatus>({
      modalVisible: false,
      state: "completed",
    });

  const styles = {
    guestListComponent: {
      paddingInline: 14,
      paddingBottom: 12,
    },

    sectionHeaderText: {
      marginBottom: 14,
      fontSize: 16,
      fontWeight: 600,
      whiteSpace: "nowrap",
      color: Colors.GRAY1,
    },
  };

  const {
    liveTotalContacts,
    liveTotalSubscribed,
    liveTotalUnsubscribed,
    liveTotalRemoved,
  } = useLiveUpdatingAudienceAnalytics({ full: true });

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadMoreUsers,
    loadUsers,
    loadSearchResultsThrottled,
    isLoading: isLoadingFollowers,
    isFinished: isFinishedLoadingFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
  });

  const {
    fetchedUserData: fetchedUnsubscribedUserData,
    fetchedFollowerData: fetchedUnsubscribedFollowerData,
    loadMoreUsers: loadMoreUnsubscribedUsers,
    loadUsers: loadUnsubscribedUsers,
    loadSearchResultsThrottled: loadUnsubscribedSearchResultsThrottled,
    isLoading: isLoadingUnsubscribedFollowers,
    isFinished: isFinishedLoadingUnsubscribedFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.UNSUBSCRIBED,
    windowSize: 30,
  });

  const {
    fetchedUserData: fetchedRemovedUserData,
    fetchedFollowerData: fetchedRemovedFollowerData,
    loadMoreUsers: loadMoreRemovedUsers,
    loadUsers: loadRemovedUsers,
    loadSearchResultsThrottled: loadRemovedSearchResultsThrottled,
    isLoading: isLoadingRemovedFollowers,
    isFinished: isFinishedLoadingRemovedFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.REMOVED,
    windowSize: 30,
  });

  useOnMount(() => {
    (async () => {
      loadUsers();
      loadUnsubscribedUsers();
      loadRemovedUsers();
      setLoadingFollowers(false);
    })();
  });

  const onExportFinish = useCallback((success: boolean) => {
    if (success) {
      setConfirmPopupExport({ modalVisible: true, state: "completed" });
    } else {
      setConfirmPopupExport({ modalVisible: true, state: "failed" });
    }
  }, []);

  const exportSpreadsheetOnPress = useCallback(async () => {
    if (confirmPopupExport.state === "in progress") {
      return;
    }

    setConfirmPopupExport({ modalVisible: true, state: "in progress" });
    await exportAllFollowerData(accountData.uid, onExportFinish);
  }, [accountData.uid, confirmPopupExport.state, onExportFinish]);

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    if (text !== "") {
      if (listValue === 0) {
        loadSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 1) {
        loadUnsubscribedSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 2) {
        loadRemovedSearchResultsThrottled(lowerCaseText);
      }
    }
    setSearchTerm(lowerCaseText);
  };

  const currentItemsToShow = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userList: AccountData[] = [];
    userList =
      listValue === 0
        ? fetchedUserData
        : listValue === 1
        ? fetchedUnsubscribedUserData
        : fetchedRemovedUserData;

    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [
    appInitialized,
    listValue,
    fetchedUserData,
    fetchedUnsubscribedUserData,
    fetchedRemovedUserData,
    searchTerm,
    contains,
  ]);

  const currentFollowerDataToShow = useMemo(
    () =>
      listValue === 0
        ? fetchedFollowerData
        : listValue === 1
        ? fetchedUnsubscribedFollowerData
        : fetchedRemovedFollowerData,
    [
      fetchedFollowerData,
      listValue,
      fetchedUnsubscribedFollowerData,
      fetchedRemovedFollowerData,
    ]
  );

  const isLoading = useMemo(
    () =>
      listValue === 0
        ? isLoadingFollowers
        : listValue === 1
        ? isLoadingUnsubscribedFollowers
        : isLoadingRemovedFollowers,
    [
      isLoadingFollowers,
      isLoadingRemovedFollowers,
      isLoadingUnsubscribedFollowers,
      listValue,
    ]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={tabValue === 0 ? "Grow Your Audience" : "No People to Show"}
            description={
              tabValue === 0
                ? "You currently have no followers. Build your audience to grow textable following."
                : ""
            }
            icon={<Icon icon="ion:people" height={64} color={Colors.GRAY1} />}
            iconBox={84}
            btnText={tabValue === 0 ? "Share Profile" : ""}
            onPress={shareProfileOnPress}
            containerStyles={{ paddingTop: 150 }}
          />
        }
      />
    );
  }, [isLoading, searchTerm, shareProfileOnPress, tabValue]);

  return (
    <TabPanel value={tabValue} index={1}>
      <div className="ColumnNormal" style={{ gap: 40, paddingBottom: 40 }}>
        <div className="ColumnNormal" style={{ paddingBottom: 48 }}>
          <div style={styles.sectionHeaderText}>
            Contacts ({liveTotalContacts})
          </div>
          <StandardListContainer
            searchComp={
              <SearchBoxContainer
                placeholder="Search People"
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginTop: 0 }}
              />
            }
            headerRightComp={
              <BlackHoverButton
                title="Export&nbsp;Data"
                iconName="ion:arrow-down"
                iconSize={14}
                onPress={exportSpreadsheetOnPress}
                containerStyles={{ paddingInline: 14, paddingBlock: 12 }}
              />
            }
            subHeaderComp={
              <ListTabs
                tabLabels={[
                  "Subscribed",
                  "Unsubscribed",
                  "Removed",
                  //   "Invalid Number",
                  //   "Blocked",
                ]}
                tabNumbers={[
                  loadingFollowers ? -1 : liveTotalSubscribed,
                  loadingFollowers ? -1 : liveTotalUnsubscribed,
                  loadingFollowers ? -1 : liveTotalRemoved,
                ]}
                selectedValue={listValue}
                onChange={setListValue}
                containerStyles={styles.guestListComponent}
                altColor={Colors.BLACK}
              />
            }
            gridHeaderComp={
              <GridTableHeader
                flexSections={FOLLOWER_USER_STATUS_FLEX_SECTIONS}
                sectionTitles={["Name", "Status", "Follow Source"]}
              />
            }
            listComp={
              <FlatList
                list={currentItemsToShow}
                renderItem={(item) => {
                  return (
                    <ProfileFollowerItem
                      item={item}
                      setProfilePreviewSelected={setProfilePreviewSelected}
                      essentialQuestion={undefined}
                      fetchedFollowerData={currentFollowerDataToShow}
                      status={true}
                    />
                  );
                }}
                renderWhenEmpty={() => renderEmptyView}
                hasMoreItems={
                  listValue === 0
                    ? !isFinishedLoadingFollowers
                    : listValue === 1
                    ? !isFinishedLoadingUnsubscribedFollowers
                    : !isFinishedLoadingRemovedFollowers
                }
                loadMoreItems={() => {
                  setTimeout(() => {
                    if (searchTerm.trim() === "") {
                      if (listValue === 0) {
                        loadMoreUsers();
                      } else if (listValue === 1) {
                        loadMoreUnsubscribedUsers();
                      } else if (listValue === 2) {
                        loadMoreRemovedUsers();
                      }
                    }
                  }, 50); // The set index has a tiny delay
                }}
                paginationLoadingIndicator={() =>
                  isLoadingFollowers && !isFinishedLoadingFollowers ? (
                    <CircularProgress
                      style={{
                        color: "#929292",
                        alignSelf: "center",
                        marginTop: "24px",
                        marginLeft: "14px",
                      }}
                      size={30}
                    />
                  ) : null
                }
              />
            }
            isLoading={loadingFollowers}
          />
        </div>
      </div>
      <ProgressActionModal
        actionTitle="Export"
        actionStatus={confirmPopupExport}
        setActionStatus={setConfirmPopupExport}
        failureMessage={
          "An error occurred while exporting. Try again or contact our hotline for help."
        }
        inProgressMessage={"Your export may take a few minutes."}
        successMessage={`Successfully exported ${
          liveTotalContacts ?? 0
        } contact${liveTotalContacts === 1 ? "" : "s"}.`}
      />
    </TabPanel>
  );
};
