import { useCallback, useMemo, useState } from "react";
import { AccountData, FollowerStatus } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { CircularProgress } from "@mui/material";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import FlatList from "flatlist-react/lib";
import GridTableHeader from "../Headers/GridTableHeader";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import { EventContentMobile } from "../LandingPage/RenderedEvents";
import { useOnMount } from "../../utils/useOnMount";
import { useLoadUserFollowList } from "../../hooks/useLoadUserFollowList";
import { hostingLiveEvents } from "@markit/common.utils";
import filter from "lodash.filter";
import { Colors } from "../../utils/colors";
import { getEventState } from "../../redux/slices/eventSlice";
import { Icon } from "@iconify/react";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { DataGraphContainer } from "./DataGraphContainer";
import StandardListContainer from "../Containers/StandardListContainer";
import { TabPanel } from "../FullEventSubComponents/TabPanel";
import EventDashboardAction from "../EventDashboard/EventDashboardAction";
import ProfileFollowerItem, {
  FOLLOWER_SHORT_USER_FLEX_SECTIONS,
  FOLLOWER_USER_FLEX_SECTIONS,
} from "../DisplayItem/ProfileItem/ProfileFollowerItem";
import { useNavigate } from "../../hooks/useNavigate";
import MainActionsDropdownMenu from "../DropdownsAndTabs/MainActionsDropdownMenu";
import { EmptyStateFlatlist } from "../EmptyStates/EmptyStateFlatlist";

type PeoplePanelProps = {
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  shareProfileOnPress: () => void;
  importOnPress: () => void;
  tabValue: number;
  setTabValue: (tabValue: number) => void;
};

export const PeoplePanel = (props: PeoplePanelProps) => {
  const {
    setProfilePreviewSelected,
    shareProfileOnPress,
    importOnPress,
    tabValue,
    setTabValue,
  } = props;
  const { accountData, appInitialized, savedQuestions, numFollowers } =
    useSelector(getAccountState).account;
  const { events } = useSelector(getEventState).events;
  const navigate = useNavigate();
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const styles = {
    headerTitle: {
      fontSize: 14,
      fontWeight: 500,
    },
    overallContainerView: {
      width: "100%",
      borderRadius: 20,
      paddingBlock: 14,
      backgroundColor: Colors.WHITE,
      height: 225,
    },
    sectionHeaderText: {
      marginBottom: 14,
      fontSize: 16,
      fontWeight: 600,
      whiteSpace: "nowrap",
      color: Colors.GRAY1,
    },
  };

  const essentialQuestion = useMemo(
    () => savedQuestions.find((question) => question.isEssential),
    [savedQuestions]
  );

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadMoreUsers,
    loadUsers,
    loadSearchResultsThrottled,
    isLoading: isLoadingFollowers,
    isFinished: isFinishedLoadingFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
  });

  useOnMount(() => {
    (async () => {
      loadUsers();
      setLoadingFollowers(false);
    })();
  });

  const upcomingEvents = useMemo(
    () => hostingLiveEvents(events, accountData.uid, true).slice(0, 3),
    [accountData.uid, events]
  );

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text.toLowerCase());
  };

  const itemsToShow: AccountData[] = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userList: AccountData[] = [];
    userList = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [appInitialized, fetchedUserData, searchTerm, contains]);

  const navigateEventOnPress = useCallback(
    (eventId: string) => {
      navigate(`/e/${eventId}/dashboard`, {
        state: { eventId: eventId },
      });
    },
    [navigate]
  );

  const navigateUpcomingEvents = useCallback(() => {
    navigate("/home/events");
  }, [navigate]);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoadingFollowers}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title="Grow Your Audience"
            description="You currently have no followers. Build your audience to grow textable following."
            icon={<Icon icon="ion:people" height={64} color={Colors.GRAY1} />}
            iconBox={84}
            btnText="Share Profile"
            onPress={shareProfileOnPress}
            containerStyles={{ paddingTop: 150 }}
          />
        }
      />
    );
  }, [isLoadingFollowers, searchTerm, shareProfileOnPress]);

  return (
    <TabPanel value={tabValue} index={0}>
      <div className="ColumnNormal" style={{ gap: 40, paddingBottom: 40 }}>
        <div className="AlignedRow" style={{ gap: 30, flex: 1 }}>
          <div style={{ flex: 1 }}>
            <MainActionsDropdownMenu
              sourceLocation="home_panel"
              overlayStyles={{ paddingInline: 80 }}
            >
              <EventDashboardAction
                title={"New"}
                iconName={"ion:add-circle"}
                iconSize={31}
                onPress={() => {}}
                visible={true}
                containerWidth={"100%"}
              />
            </MainActionsDropdownMenu>
          </div>
          <div style={{ flex: 1 }}>
            <EventDashboardAction
              title={"Share Profile"}
              iconName={"ion:share"}
              iconSize={31}
              onPress={shareProfileOnPress}
              visible={true}
              containerWidth={"100%"}
            />
          </div>
          <div style={{ flex: 1 }}>
            <EventDashboardAction
              title={"Import Contacts"}
              iconName={"ion:cloud-upload"}
              iconSize={31}
              onPress={importOnPress}
              visible={true}
              containerWidth={"100%"}
            />
          </div>
        </div>
        <div className="ColumnNormal">
          <div style={styles.sectionHeaderText}>Shortcuts</div>
          <div className="RowNormal" style={{ gap: 30 }}>
            <StandardBorderedContainer
              containerStyles={styles.overallContainerView}
            >
              <div className="ColumnNormalSelect">
                <div
                  className="AlignedRowSelect"
                  style={{ gap: 7, paddingInline: 14 }}
                  onClick={() => setTabValue(1)}
                >
                  <span style={styles.headerTitle}>Your Audience</span>
                  <Icon
                    icon="ion:chevron-right"
                    height={12}
                    color={Colors.BLACK}
                  />
                </div>
                <HorizontalDivider altMargin={14} />
                <DataGraphContainer />
              </div>
            </StandardBorderedContainer>
            <StandardBorderedContainer
              containerStyles={styles.overallContainerView}
            >
              <div className="ColumnNormal" style={{ position: "relative" }}>
                <div
                  onClick={navigateUpcomingEvents}
                  className="AlignedRowSelect"
                  style={{ gap: 7, paddingInline: 14 }}
                >
                  <span style={styles.headerTitle}>Upcoming Events</span>
                  <Icon
                    icon="ion:chevron-right"
                    height={12}
                    color={Colors.BLACK}
                  />
                </div>
                <HorizontalDivider altMargin={14} />
                {upcomingEvents.length > 0 ? (
                  <div
                    className="ColumnNormal"
                    style={{ paddingInline: 14, gap: 10 }}
                  >
                    {upcomingEvents.map((event) => (
                      <div onClick={() => navigateEventOnPress(event.id)}>
                        <EventContentMobile
                          event={event}
                          altSize="48px"
                          altFontSize={12}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    onClick={navigateUpcomingEvents}
                    className="Centering"
                    style={{ cursor: "pointer" }}
                  >
                    <EmptyStateButton
                      title="No Events"
                      icon={
                        <Icon
                          icon="ion:calendar"
                          height={42}
                          color={Colors.GRAY1}
                        />
                      }
                      iconBox={64}
                      containerStyles={{ paddingBlock: 24, cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
            </StandardBorderedContainer>
          </div>
        </div>
        <div className="ColumnNormal" style={{ paddingBottom: 48 }}>
          <div style={styles.sectionHeaderText}>
            Subscribed ({numFollowers})
          </div>
          <StandardListContainer
            searchComp={
              <SearchBoxContainer
                placeholder="Search People"
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginTop: 0 }}
              />
            }
            gridHeaderComp={
              <GridTableHeader
                flexSections={
                  essentialQuestion
                    ? FOLLOWER_USER_FLEX_SECTIONS
                    : FOLLOWER_SHORT_USER_FLEX_SECTIONS
                }
                sectionTitles={
                  essentialQuestion
                    ? ["Name", "Follow Source", "Essential"]
                    : ["Name", "Follow Source"]
                }
              />
            }
            listComp={
              <FlatList
                list={itemsToShow}
                renderItem={(item) => {
                  return (
                    <ProfileFollowerItem
                      item={item}
                      setProfilePreviewSelected={setProfilePreviewSelected}
                      essentialQuestion={essentialQuestion}
                      fetchedFollowerData={fetchedFollowerData}
                      status={false}
                    />
                  );
                }}
                renderWhenEmpty={() => renderEmptyView}
                hasMoreItems={!isFinishedLoadingFollowers}
                loadMoreItems={() => {
                  setTimeout(() => {
                    if (searchTerm.trim() === "") {
                      loadMoreUsers();
                    }
                  }, 50); // The set index has a tiny delay
                }}
                paginationLoadingIndicator={() =>
                  isLoadingFollowers && !isFinishedLoadingFollowers ? (
                    <CircularProgress
                      style={{
                        color: "#929292",
                        alignSelf: "center",
                        marginTop: "24px",
                        marginLeft: "14px",
                      }}
                      size={30}
                    />
                  ) : null
                }
              />
            }
            isLoading={loadingFollowers}
          />
        </div>
      </div>
    </TabPanel>
  );
};
