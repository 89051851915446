import "../../css/GlobalStyles.css";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState, LoginState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "../../hooks/useNavigate";
import RectangleButtonCreatorPanel from "../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../components/Containers/HelpModal";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import {
  EventbriteWebhookType,
  IntegrationPhoneContact,
  IntegrationPhoneContactState,
} from "@markit/common.types";
import ProgressActionModal, {
  ProgressActionStatus,
} from "../../components/Containers/ProgressActionModal";
import MultiStageContainer from "../../components/Containers/MultiStageContainer";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import { API } from "../../API";
import IntegrationConfirm from "../../components/Integrations/IntegrationConfirm";
import { hasSubscriptionUnlockedAdvancedData } from "@markit/common.utils";
import ConfirmActionModal from "../../components/Containers/ConfirmPopups/ConfirmActionModal";
import { configureAutoCollectPhoneQuestion } from "../../utils/eventbriteUtils";
import IntegrationCustomize from "../../components/Integrations/IntegrationCustomize";
import LoadingScreen from "../LoadingScreen";
import ComplianceConsentTerms from "../../components/Compliance/ComplianceConsentTerms";
import { useSearchParams } from "react-router-dom";

export enum EventbriteIntegrationStage {
  LOGGED_IN = "Logged into Eventbrite",
  CUSTOMIZE = "Customize",
  CONFIRM = "Confirm",
}

const EventbriteIntegration = () => {
  const { accountData, appInitialized, loggedIn } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [integrationStage, setIntegrationStage] = useState(
    EventbriteIntegrationStage.CUSTOMIZE
  );
  const [loadingConnection, setLoadingConnection] = useState(true);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [phoneContacts, setPhoneContacts] = useState<IntegrationPhoneContact[]>(
    []
  );
  const [autoCollectPhone, setAutoCollectPhone] = useState(true);
  const [syncStatusVisible, setSyncStatusVisible] =
    useState<ProgressActionStatus>({
      modalVisible: false,
      state: "completed",
    });
  const [confirmSync, setConfirmSync] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [complianceChecked, setComplianceChecked] = useState(false);

  const isEditingConnection = useMemo(
    () => accountData.eventbriteInfo.syncedOn !== "",
    [accountData.eventbriteInfo.syncedOn]
  );

  const newValidContacts = useMemo(
    () =>
      phoneContacts.filter(
        (contact) => contact.state === IntegrationPhoneContactState.NEW
      ),
    [phoneContacts]
  );

  const noPhoneNumbersFound = useMemo(
    () => newValidContacts.length === 0,
    [newValidContacts.length]
  );

  const stageNames = useMemo(
    () =>
      isEditingConnection
        ? [
            EventbriteIntegrationStage.LOGGED_IN,
            EventbriteIntegrationStage.CUSTOMIZE,
          ]
        : [
            EventbriteIntegrationStage.LOGGED_IN,
            EventbriteIntegrationStage.CUSTOMIZE,
            EventbriteIntegrationStage.CONFIRM,
          ],
    [isEditingConnection]
  );

  const disableRightButton = useMemo(
    () =>
      isEditingConnection &&
      accountData.eventbriteInfo.autoAddPhoneQuestion === autoCollectPhone,
    [
      accountData.eventbriteInfo.autoAddPhoneQuestion,
      autoCollectPhone,
      isEditingConnection,
    ]
  );

  useEffect(() => {
    (async () => {
      const hasError = searchParams.get("error");
      if (hasError === "true") {
        alert(
          "There is already another Markit account connected to this Eventbrite account. Please try connecting with a different Eventbrite account. You will be redirected."
        );
        navigate("/home", { state: { initialTabValue: 4 } });
        return;
      }
      if (loggedIn === LoginState.LOGGED_IN && !appInitialized) {
        return;
      }
      // Redirect back to integrations tab if user has synced with eventbrite, has not connected to eventbrite, or does not have advanced subscription
      if (
        !hasSubscriptionUnlockedAdvancedData(accountData) &&
        !accountData.isAdmin
      ) {
        navigate("/home", { state: { initialTabValue: 4 } });
        return;
      }
      if (isEditingConnection) {
        setAutoCollectPhone(accountData.eventbriteInfo.autoAddPhoneQuestion);
      }
      setLoadingInitial(false);
      if (!isEditingConnection) {
        const { phoneContacts, success } =
          await API.eventScraper.fetchEventbritePhoneContacts({
            eventbriteInfo: accountData.eventbriteInfo,
            userId: accountData.uid,
          });
        if (!success) {
          alert("There was an error connecting with Eventbrite at this time.");
          return;
        }
        setPhoneContacts(phoneContacts);
      }
      setLoadingConnection(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData.uid, appInitialized]);

  const syncContactsOnPress = useCallback(async () => {
    setSyncStatusVisible({
      modalVisible: true,
      state: "in progress",
    });
    await API.eventScraper
      .createNewPhoneFollowers({
        userId: accountData.uid,
        phoneContacts: newValidContacts,
        eventbriteInfo: accountData.eventbriteInfo,
        autoAddPhoneQuestion: autoCollectPhone,
      })
      .then((response) => {
        const { success } = response;
        setSyncStatusVisible({
          modalVisible: true,
          state: success ? "completed" : "failed",
        });
      })
      .catch((err: any) => {
        console.log(err.message);
        setSyncStatusVisible({ modalVisible: true, state: "failed" });
      });
  }, [
    accountData.eventbriteInfo,
    accountData.uid,
    autoCollectPhone,
    newValidContacts,
  ]);

  const continueOnPress = useCallback(async () => {
    if (integrationStage === EventbriteIntegrationStage.CUSTOMIZE) {
      if (isEditingConnection) {
        await configureAutoCollectPhoneQuestion(
          accountData.eventbriteInfo,
          accountData.uid,
          autoCollectPhone
            ? EventbriteWebhookType.CREATE
            : EventbriteWebhookType.DELETE
        );
        navigate("/home", { state: { initialTabValue: 4 } });
      } else {
        setIntegrationStage(EventbriteIntegrationStage.CONFIRM);
      }
    } else {
      setConfirmSync(true);
    }
  }, [
    accountData.eventbriteInfo,
    accountData.uid,
    autoCollectPhone,
    integrationStage,
    isEditingConnection,
    navigate,
  ]);

  const backOnPress = useCallback(() => {
    if (integrationStage === EventbriteIntegrationStage.CONFIRM) {
      setIntegrationStage(EventbriteIntegrationStage.CUSTOMIZE);
    } else {
      navigate("/home", { state: { initialTabValue: 4 } });
    }
  }, [integrationStage, navigate]);

  if (!isDesktop) {
    return <EmptyViewStateMobile />;
  }

  if (loadingInitial) {
    return <LoadingScreen isLoadingPage={loadingInitial} />;
  }

  return (
    <CreatorModeWrapper>
      <div
        className="ColumnNormal"
        style={{ gap: 24, height: "calc(100vh - 60px)" }}
      >
        <div className="CreatorPanelContainer">
          <div className="AlignedRowSpaced">
            <h2>Connect Eventbrite</h2>
            <RectangleButtonCreatorPanel
              title="Need Help?"
              onPress={() => setShowHelpModal(true)}
              iconName=""
              addHover
            />
          </div>
        </div>
        <div
          className="CreatorPanelContainer"
          style={{ backgroundColor: Colors.WHITE1, flex: 1, paddingBottom: 40 }}
        >
          <MultiStageContainer
            currentStageIndex={stageNames.findIndex(
              (stage) => stage === integrationStage
            )}
            stageNames={stageNames}
            backOnPress={backOnPress}
            fullLoading={loadingConnection}
            rightButton={
              <RectangleButton
                buttonLabel={
                  <span style={{ fontSize: 14, fontWeight: 500 }}>
                    {isEditingConnection
                      ? "Confirm"
                      : integrationStage ===
                        EventbriteIntegrationStage.CUSTOMIZE
                      ? "Continue"
                      : "Confirm and Sync"}
                  </span>
                }
                disabled={disableRightButton}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                altPaddingVert={14}
                altPaddingHorz={40}
                onPress={continueOnPress}
                iconRight={
                  <Icon
                    icon="mdi:chevron-right"
                    height={16}
                    color={
                      loadingConnection || disableRightButton
                        ? Colors.GRAY1
                        : Colors.WHITE
                    }
                  />
                }
              />
            }
          >
            {integrationStage === EventbriteIntegrationStage.CUSTOMIZE ? (
              <IntegrationCustomize
                noPhoneNumbersFound={noPhoneNumbersFound}
                isEditingConnection={isEditingConnection}
                autoCollectPhone={autoCollectPhone}
                setAutoCollectPhone={setAutoCollectPhone}
              />
            ) : (
              <IntegrationConfirm
                phoneContacts={phoneContacts}
                autoCollectPhone={autoCollectPhone}
              />
            )}
          </MultiStageContainer>
        </div>
      </div>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <ConfirmDeleteModal
        heading="Are you sure want to exit?"
        subtext="Your changes will be discarded."
        deleteButtonText="Exit"
        hideModal={!showConfirmDelete}
        setIsVisible={setShowConfirmDelete}
        deleteOnPress={() => {}}
        icon={<Icon icon="ion:exit" height={40} />}
      />
      <ConfirmActionModal
        heading={"Ready to Sync?"}
        subtext={
          <div>
            <span className="smallBodySubtext">
              You can edit your connection at any time.
            </span>
            <ComplianceConsentTerms
              isChecked={complianceChecked}
              setIsChecked={setComplianceChecked}
              preText="You acknowledge and agree that if you collect phone numbers through Eventbrite without using Markit's auto added form questions,"
            />
          </div>
        }
        confirmButtonText={"Sync"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!confirmSync}
        setIsVisible={setConfirmSync}
        confirmOnPress={syncContactsOnPress}
        disableConfirm={!complianceChecked}
      />
      <ProgressActionModal
        actionTitle="Sync"
        actionStatus={syncStatusVisible}
        setActionStatus={setSyncStatusVisible}
        failureMessage={
          "An error occurred while syncing. Try again or contact our hotline for help."
        }
        inProgressMessage={"Syncing may take a few minutes."}
        successMessage={
          noPhoneNumbersFound
            ? "Successfully Synced!"
            : `Successfully synced ${newValidContacts.length} contact${
                newValidContacts.length !== 1 ? "s" : ""
              }.`
        }
        addOnCompletion={() =>
          navigate("/home", { state: { initialTabValue: 4 } })
        }
      />
    </CreatorModeWrapper>
  );
};

export default memo(EventbriteIntegration);
