import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import ContactSupportButton from "../Buttons/ContactSupportButton";
import SwitchToggleItem from "../SwitchToggleItem";
import EventbritePhoneTemplate from "../../../src/assets/EventbritePhoneTemplate.png";

type IntegrationCustomizeProps = {
  noPhoneNumbersFound: boolean;
  isEditingConnection: boolean;
  autoCollectPhone: boolean;
  setAutoCollectPhone: (autoCollectPhone: boolean) => void;
};

const IntegrationCustomize = (props: IntegrationCustomizeProps) => {
  const {
    noPhoneNumbersFound,
    isEditingConnection,
    autoCollectPhone,
    setAutoCollectPhone,
  } = props;

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    container: { paddingBlock: 14, backgroundColor: Colors.WHITE1 },
  };

  return (
    <div
      className="ColumnNormal Centering"
      style={{ gap: 40, paddingBlock: 30 }}
    >
      <div
        className="ColumnNormal"
        style={{ gap: 10, textAlign: "center", width: 607 }}
      >
        <span style={styles.headerText}>Customize your sync</span>
        {noPhoneNumbersFound && !isEditingConnection ? (
          <span style={{ paddingTop: 24 }} className="bodyMedium">
            We didn't find any phone numbers in your Eventbrite audience.
            <br />
            From now on, we will automatically collect attendee phone numbers
            for you.
          </span>
        ) : null}
      </div>
      <div className="ColumnNormal" style={{ gap: 40, width: 547 }}>
        <StandardBorderedContainer
          containerStyles={{ ...styles.container, paddingInline: 14 }}
        >
          <SwitchToggleItem
            title="Automatically Collect Attendee Phone Numbers"
            description="Automatically add a host question that collects my attendee's phone numbers to all my upcoming Eventbrite Events."
            toggleValue={autoCollectPhone}
            onChange={() => setAutoCollectPhone(!autoCollectPhone)}
          />
        </StandardBorderedContainer>
        <div className="ColumnNormal Centering" style={{ gap: 10 }}>
          <span className="bodySubtext">
            This is what auto-added questions will look like on your Eventbrite
            checkout:
          </span>
          <img
            style={{ height: "auto", width: "90%" }}
            alt="EventbritePhoneTemplate"
            src={EventbritePhoneTemplate}
          />
        </div>
      </div>
      <ContactSupportButton />
    </div>
  );
};

export default IntegrationCustomize;
