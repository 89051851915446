import React, { memo } from "react";

type InitialsPicProps = {
  fullName?: string;
  isLink?: boolean;
  size?: number;
  pointer?: boolean;
};

export const InitialsPic = memo(function InitialsPicFn(
  props: InitialsPicProps
) {
  const { fullName, isLink, size, pointer } = props;
  const getInitials = (fullName: string) => {
    let parts = fullName.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length && i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials.toUpperCase();
  };
  return fullName ? (
    <div
      style={{ fontSize: size, cursor: isLink || pointer ? "pointer" : "auto" }}
    >
      {getInitials(fullName)}
    </div>
  ) : null;
});
