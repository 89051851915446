import {
  AccountData,
  FollowerStatus,
  ImportData,
  SpreadsheetStatus,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useMemo, useState } from "react";
import { filter } from "lodash";
import { Colors } from "../../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import { MassTextsPersonItem } from "../../Items/MassTextsPersonItem";
import { EmptySearchState } from "../../../../EmptyStates/EmptySearchState";
import { useOnMount } from "../../../../../utils/useOnMount";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { SelectRecipientsSharedProps } from "../SelectRecipientsPanel";
import {
  addOrFilterRecipients,
  filterFollowersByStatus,
  textableContactString,
} from "@markit/common.utils";
import { getUserSpreadsheetContacts } from "../../../../../utils/spreadsheetUtils";
import { GetLongDate } from "../../../../../utils/GetLongDate";
import { useLoadSpreadsheetUserList } from "../../../../../hooks/useLoadSpreadsheetUserList";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";

type SelectRecipientsImportProps = SelectRecipientsSharedProps & {
  selectedImport: ImportData;
};

const SelectRecipientsImport = (props: SelectRecipientsImportProps) => {
  const {
    selectedImport,
    unsavedRecipients,
    setUnsavedRecipients,
    excludingMode,
    setProfileSelected,
  } = props;
  const { accountData, followersData } = useSelector(getAccountState).account;
  const [peopleSearchTerm, setPeopleSearchTerm] = useState<string>("");
  const [allSpreadsheetUsers, setAllSpreadsheetUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const {
    fetchedUserData: fetchedFollowerData,
    loadMoreUsers: loadMoreFollowerUsers,
    isLoading: isFetchedFollowersLoading,
    isFinished: isFinishedFollowers,
    loadSearchResultsThrottled,
  } = useLoadSpreadsheetUserList({
    userId: accountData.uid,
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 20,
    status: SpreadsheetStatus.ACCEPTED,
    fileId: selectedImport.spreadsheet.fid,
  });

  const allSubscribedSpreadsheetUsers = useMemo(
    () =>
      filterFollowersByStatus(
        allSpreadsheetUsers,
        followersData,
        FollowerStatus.SUBSCRIBED
      ),
    [allSpreadsheetUsers, followersData]
  );

  useOnMount(() => {
    (async () => {
      if (
        loadingUsers &&
        fetchedFollowerData.length === 0 &&
        !isFinishedFollowers
      ) {
        loadMoreFollowerUsers();
        setLoadingUsers(false);
      }

      const spreadsheetUserIds = await getUserSpreadsheetContacts(
        accountData.uid,
        selectedImport.spreadsheet.fid
      );
      setAllSpreadsheetUsers(spreadsheetUserIds);
      setLoading(false);
    })();
  });

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setPeopleSearchTerm(text);
  };

  const itemsToShow: AccountData[] = useMemo(() => {
    if (peopleSearchTerm === "") {
      return fetchedFollowerData;
    }
    let userList: AccountData[] = fetchedFollowerData;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, peopleSearchTerm.toLowerCase());
    });
    return userList;
  }, [peopleSearchTerm, fetchedFollowerData, containsUser]);

  const isAllSelected = useCallback(
    (totalList: string[], subList: string[]) =>
      subList.length > 0
        ? totalList.every((sel) => subList.includes(sel))
        : false,
    []
  );

  return (
    <div>
      <div
        className="ColumnCenter"
        style={{
          gap: 4,
          backgroundColor: Colors.GRAY6,
          padding: 10,
          borderRadius: 12,
        }}
      >
        <span className="bodyMedium">
          {selectedImport.spreadsheet.fileName}
        </span>
        <span className="smallBodySubtext">
          {GetLongDate(
            selectedImport.spreadsheet.createdAt,
            true,
            true,
            true,
            true
          )}
        </span>
        <span className="smallBodySubtext">
          {textableContactString(selectedImport.textableContacts)}
        </span>
      </div>
      <SearchBoxContainer
        placeholder="Search People"
        onChange={(e) => handleSearch(e.target.value)}
        value={peopleSearchTerm}
        containerStyles={{ marginBottom: 10 }}
      />
      <div
        className="HideScrollbar"
        style={{
          height: "calc(100vh - 280px)",
          overflow: "scroll",
          paddingBottom: 20,
        }}
      >
        {itemsToShow.length > 0 && !isFetchedFollowersLoading ? (
          <div>
            <MassTextsActionItem
              title={"Select All"}
              icon={<Icon icon="ion:people" height={32} color={iconColor} />}
              onPress={() =>
                addOrFilterRecipients(
                  unsavedRecipients,
                  setUnsavedRecipients,
                  allSubscribedSpreadsheetUsers
                )
              }
              subtext={
                loading
                  ? ""
                  : `${allSubscribedSpreadsheetUsers.length} ${
                      allSubscribedSpreadsheetUsers.length !== 1
                        ? "People"
                        : "Person"
                    }`
              }
              isCheckSelected={isAllSelected(
                allSubscribedSpreadsheetUsers,
                unsavedRecipients
              )}
              checkColor={iconColor}
            />
            <hr style={{ marginBlock: 10 }} />
          </div>
        ) : null}
        <FlatList
          list={itemsToShow}
          renderItem={(user) => {
            return (
              <MassTextsPersonItem
                user={user}
                unsavedPeople={unsavedRecipients}
                setUnsavedPeople={setUnsavedRecipients}
                excludingMode={excludingMode}
                setProfileSelected={setProfileSelected}
              />
            );
          }}
          renderWhenEmpty={() => (
            <EmptyStateFlatlist
              containerStyles={{ paddingTop: 100 }}
              searchTerm={peopleSearchTerm}
              isLoading={isFetchedFollowersLoading}
              nonSearchEmptyView={
                <EmptySearchState mainText="No people to show" />
              }
            />
          )}
          hasMoreItems={!isFinishedFollowers}
          loadMoreItems={() => {
            loadMoreFollowerUsers();
          }}
          paginationLoadingIndicator={() =>
            isFetchedFollowersLoading && !isFinishedFollowers ? (
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={20}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default SelectRecipientsImport;
