import {
  EventbriteInfo,
  EventbriteWebhook,
  EventbriteWebhookType,
} from "@markit/common.types";
import { API } from "../API";

// When a user edits their eventbrite integration and turns on/off the auto collect phone number
export const configureAutoCollectPhoneQuestion = async (
  eventbriteInfo: EventbriteInfo,
  userId: string,
  type: EventbriteWebhookType
) => {
  await API.eventScraper.configureEventbriteWebhook({
    eventbriteInfo: eventbriteInfo,
    userId: userId,
    action: EventbriteWebhook.EVENT_CREATED,
    type: type,
  });
};

// Determines if the user has last synced their data in the past hour
// If admin, always allow resyncing
export const checkLastEventbriteSyncDate = (
  syncedOn: string,
  isAdmin: boolean
) => {
  if (isAdmin) {
    return false;
  }
  const lastSyncDate = new Date(syncedOn);
  const diffInMilliseconds = Math.abs(
    new Date().getTime() - lastSyncDate.getTime()
  );
  const oneHourInMilliseconds = 3600000;
  const lastSyncWithinHour = diffInMilliseconds <= oneHourInMilliseconds;
  if (lastSyncWithinHour) {
    return true;
  }
  return false;
};
