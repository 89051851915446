import {
  AccountData,
  FollowType,
  Follower,
  FollowerStatus,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { useCallback, useMemo, useState } from "react";
import ProfileItem from "../../ProfileItem";
import { Colors } from "../../../utils/colors";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { useOnMount } from "../../../utils/useOnMount";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { fetchUserEssentialFormResponse } from "../../../utils/userUtils";
import EssentialDataResponse from "../../FollowerProfile/EssentialDataResponse";
import LabelButton from "../../Buttons/LabelButton";

export const FOLLOWER_USER_FLEX_SECTIONS = [5, 2, 3];
export const FOLLOWER_SHORT_USER_FLEX_SECTIONS = [5, 2];
export const FOLLOWER_USER_STATUS_FLEX_SECTIONS = [14, 3, 6];

type ProfileFollowerItemProps = {
  item: AccountData;
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  essentialQuestion: SavedFormQuestion | undefined;
  fetchedFollowerData: Follower[];
  status: boolean;
};

const ProfileFollowerItem = ({
  item,
  setProfilePreviewSelected,
  essentialQuestion,
  fetchedFollowerData,
  status,
}: ProfileFollowerItemProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const [essentialData, setEssentialData] = useState<SavedFormResponse>();

  const styles = {
    subtext: { fontSize: 14, color: Colors.GRAY1 },
  };

  const flexSections = useMemo(
    () =>
      status
        ? FOLLOWER_USER_STATUS_FLEX_SECTIONS
        : essentialQuestion
        ? FOLLOWER_USER_FLEX_SECTIONS
        : FOLLOWER_SHORT_USER_FLEX_SECTIONS,
    [essentialQuestion, status]
  );

  const viewProfilePreview = useCallback(() => {
    setProfilePreviewSelected(item);
  }, [setProfilePreviewSelected, item]);

  useOnMount(() => {
    (async () => {
      const essentialFormResponse = await fetchUserEssentialFormResponse(
        accountData.uid,
        item.uid,
        essentialQuestion ? essentialQuestion.id : ""
      );

      setEssentialData(essentialFormResponse);
    })();
  });

  const followerData = useMemo(
    () => fetchedFollowerData.find((follower) => follower.uid === item.uid),
    [fetchedFollowerData, item.uid]
  );

  const followSourceToShow = useMemo(
    () =>
      followerData
        ? followerData.followType === FollowType.UPLOAD
          ? followerData.spreadsheetData.fileName !== "Imported from Contacts"
            ? `Imported from ${followerData.spreadsheetData.fileName}`
            : followerData.spreadsheetData.fileName
          : followerData.followType
        : "---",
    [followerData]
  );

  return (
    <div>
      <div className="AlignedRow" style={{ paddingBlock: 10 }}>
        <div style={{ marginLeft: 14, flex: flexSections[0] }}>
          <ProfileItem profileItem={item} onItemPress={viewProfilePreview} />
        </div>
        {status ? (
          <div className="AlignedRowSpaced" style={{ flex: flexSections[1] }}>
            <LabelButton
              color={
                followerData &&
                followerData.status === FollowerStatus.SUBSCRIBED
                  ? Colors.GREEN2
                  : Colors.RED3
              }
              backgroundColor={Colors.WHITE1}
              text={followerData ? followerData.status : ""}
              containerStyles={{ marginLeft: 14 }}
            />
          </div>
        ) : null}
        <div
          style={{
            flex: status ? flexSections[2] : flexSections[1],
            marginLeft: 14,
          }}
        >
          <span
            className="TextOverflowEllipsis"
            style={{ marginLeft: 14, maxWidth: "12vw", ...styles.subtext }}
          >
            {followSourceToShow}
          </span>
        </div>
        {essentialQuestion && !status ? (
          <div
            style={{
              flex: flexSections[2],
              marginLeft: 28,
            }}
          >
            <div style={{ ...styles.subtext }}>
              <EssentialDataResponse
                savedQuestion={essentialQuestion}
                formResponse={essentialData}
                userData={accountData}
              />
            </div>
          </div>
        ) : null}
      </div>
      <HorizontalDivider />
    </div>
  );
};

export default ProfileFollowerItem;
