import { useMemo, useState, useCallback } from "react";
import { CustomTicketV2, PromoCode } from "@markit/common.types";
import { applyPromoCode } from "./eventUtils/eventUtils";

type useFullEventPricesProps = {
  selectedCustomTicket: CustomTicketV2 | undefined;
  tixId: string;
  ticketQuantity: number;
  eventHostId: string;
  promoCode?: PromoCode;
};

export const useFullEventPrices = (props: useFullEventPricesProps) => {
  const {
    selectedCustomTicket,
    tixId,
    promoCode,
    eventHostId,
    ticketQuantity,
  } = props;

  const [currPromoCode, setCurrPromoCode] = useState(promoCode);
  const [currTixId, setCurrTixId] = useState(tixId);

  const ticketPriceCents = useMemo(
    () =>
      selectedCustomTicket
        ? currTixId
          ? 0
          : selectedCustomTicket.price * ticketQuantity
        : 0,
    [selectedCustomTicket, ticketQuantity, currTixId]
  );

  const discountedTicketPriceCents = useMemo(
    () =>
      currPromoCode && selectedCustomTicket
        ? Math.round(
            applyPromoCode(
              selectedCustomTicket,
              currPromoCode,
              ticketQuantity
            ) ?? ticketPriceCents
          )
        : ticketPriceCents,
    [currPromoCode, selectedCustomTicket, ticketPriceCents, ticketQuantity]
  );

  // TODO (jonathan): this will use an accountData field in future
  // custom fee for 212groupnyc and glamhospitality
  const ticketFeesCents = useMemo(
    () =>
      discountedTicketPriceCents !== 0
        ? Math.round(
            ((discountedTicketPriceCents / ticketQuantity) *
              (eventHostId === "w2fWCSAnieMjmxbN2auESmUaqMm2"
                ? 0.1165
                : eventHostId === "qB9dhzxgPYf62LHiMQh7yhSifaX2"
                ? 0.1125
                : 0.06) +
              (eventHostId === "8NLDFMcDZJctQQlhgm0gNJw9yMk1" ? 359 : 159)) *
              ticketQuantity
          )
        : 0,
    [discountedTicketPriceCents, eventHostId, ticketQuantity]
  );

  const totalAmountCents = useMemo(
    () =>
      Math.round(currTixId ? 0 : discountedTicketPriceCents + ticketFeesCents),
    [discountedTicketPriceCents, ticketFeesCents, currTixId]
  );

  const updatePromoCode = useCallback(async (promoCode) => {
    setCurrPromoCode(promoCode);
  }, []);

  const updateTixId = useCallback(async (tixId) => {
    setCurrTixId(tixId);
  }, []);

  return {
    discountedTicketPriceCents,
    ticketFeesCents,
    totalAmountCents,
    currPromoCode,
    updatePromoCode,
    currTixId,
    updateTixId,
  };
};
