import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { Icon } from "@iconify/react";

type ListDropdownProp = {
  dropdownLabels: string[];
  dropdownNumbers: number[];
  selectedValue: number;
  onChange: (index: number) => void;
  containerStyles?: React.CSSProperties;
  tabStyles?: React.CSSProperties;
  altColor?: string;
  altTextColor?: string;
  loading?: boolean;
};

const ListDropdown = (props: ListDropdownProp) => {
  const {
    dropdownLabels,
    dropdownNumbers,
    selectedValue,
    onChange,
    containerStyles,
    tabStyles,
    altColor,
    altTextColor,
    loading,
  } = props;

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleOpenChange = useCallback(
    (nextOpen: boolean, info?: { source: "trigger" | "menu" }) => {
      if (info?.source === "trigger" || nextOpen) {
        setOpenDropdown(nextOpen);
      }
    },
    []
  );

  const styles = {
    tabContainer: {
      transition: "all 200ms ease",
      borderRadius: 20,
      paddingBlock: 8,
      paddingInline: 10,
      gap: 5,
    },
    tabText: {
      fontSize: 14,
      fontWeight: "500",
    },
  };

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = dropdownLabels.map((label, index) => {
      return {
        title: label + ` (${dropdownNumbers[index]})`,
        icon: selectedValue === index ? "ion:checkmark-circle" : "",
        key: label,
        onPress: () => {
          onChange(index);
          setOpenDropdown(false);
        },
      };
    });

    return menuItems;
  }, [dropdownLabels, dropdownNumbers, onChange, selectedValue]);

  return (
    <DropdownMenu
      trigger={["click"]}
      placement="bottomLeft"
      altWidth={200}
      topPadding={0}
      dropdownItems={dropdownItems}
      isOpen={openDropdown}
      onOpenChange={handleOpenChange}
    >
      <div className="AlignedRow" style={{ gap: 4, ...containerStyles }}>
        <div
          className="AlignedRowSelect"
          style={{
            ...styles.tabContainer,
            ...tabStyles,
            backgroundColor: altColor || Colors.BLUE5,
          }}
        >
          <span
            style={{
              ...styles.tabText,
              color: altTextColor ?? Colors.WHITE,
            }}
          >
            {dropdownLabels[selectedValue]}
          </span>
          {dropdownNumbers[selectedValue] !== -1 ? (
            <span
              style={{
                ...styles.tabText,
                color: altTextColor ?? Colors.WHITE,
              }}
            >
              {loading ? "" : `(${dropdownNumbers[selectedValue]})`}
            </span>
          ) : null}
          <Icon
            icon={!openDropdown ? "ion:chevron-down" : "ion:chevron-up"}
            height={20}
            style={{ color: altTextColor ?? Colors.WHITE }}
          />
        </div>
      </div>
    </DropdownMenu>
  );
};

export default ListDropdown;
